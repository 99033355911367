import React from 'react';
//Material-UI

import { IconButton, Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { ButtonTooltip } from '../ButtonTooltip';

export default function AlertDialog(props: any) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        handleClose();
        props.handleAgree(props.entity);
    };

    return (
        <div>
            <ButtonTooltip
                isDisabled={props.disabled}
                tooltipTitle={props.buttonTooltipTitle}
                titleDisabled={props.buttonTooltipTitleDisabled}
            >
                <IconButton
                    disabled={props.disabled}
                    className='text-error'
                    onClick={(e) => handleClickOpen()}
                >
                    <DeleteOutlinedIcon />
                </IconButton>
            </ButtonTooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className="dialog-delete">
                    <IconButton disabled>
                        <DeleteOutlinedIcon style={{ color: '#fb4b4e', fontSize: '36px' }} />
                    </IconButton>
                    <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
                    <p id="alert-dialog-subtitle">{props.dialogSubtitle}</p>
                    <DialogActions className="btns-container">
                        <Button className="btn btn_basic" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button className="btn btn_basic delete" onClick={(e) => handleConfirm()} autoFocus>
                            {props.deleteMessage}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
