import React, { useEffect } from 'react';
//Material-UI
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

interface IProps {
    setShowAlert: any;
    showAlert: boolean;
    discardTextChanges: any;
}
export default function AlertComponent(props: IProps) {
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        setOpen(props.showAlert);
    }, [props.showAlert]);
    const handleClose = () => {
        props.setShowAlert(false);
    };

    const handleConfirm = () => {
        props.setShowAlert(false);
        props.discardTextChanges();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {
                        'You will lose the changes you made to summary, recommendation and follow-up, are you sure to want to proceed?'
                    }
                </DialogTitle>
                <DialogActions>
                    <Button className="btn btn_outline" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="btn btn_highlight" onClick={handleConfirm} autoFocus>
                        Edit table
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
