import React, { FC } from "react";
import { Text, View, StyleSheet, Font, Page } from "@react-pdf/renderer";
import { IAnswer, IAnswerOption, ICategory, IQuestion } from "../../../../interfaces";
import halcomBold from './../../../../../src/assets/fonts/halcom/HalcomBold/font.ttf';

type TableProps = {
    categories: ICategory[],
    questionsByCategory: { [categoryId: number]: IQuestion[] },
}

/*
In the result .PDF file, there is a table with all questions and answers
This component is responsible for rendering it.
*/
const PDFquestionSummaryTable: FC<TableProps> = (props) => {

    Font.register({
        family: 'Halcom-Bold',
        src: halcomBold
    });
    const primaryColor = '#5a5677';
    const styles = StyleSheet.create({
        tablePage: {
            backgroundColor: 'white',
            color: primaryColor,
            fontFamily: 'Halcom-Bold',
            paddingBottom: 80
        },
        tableContainer: {
            flexDirection: "row",
            flexWrap: "wrap",
            paddingLeft: 30,
            paddingRight: 30
        },
        headerRow: {
            fontSize: 12,
            fontFamily: 'Halcom-Bold',
            flexDirection: "row",
            alignItems: "flex-start",
            paddingTop: 20,
            paddingBottom: 20,
            color: "black"
        },
        row: {
            fontSize: 9,
            fontFamily: 'Halcom-Bold',
            color: primaryColor,
            flexDirection: "row",
            alignItems: "flex-start",
            paddingBottom: 9,
            paddingTop: 6
        },
        topic: {
            width: "18%",
            paddingRight: 3,
            color: "black"
        },
        question: {
            width: "57%",
            paddingLeft: 3,
            paddingRight: 20
        },
        answer: {
            width: "10%",
            paddingLeft: 5,
            paddingRight: 3,
            fontFamily: 'Halcom-Bold',
            alignItems: 'flex-end'
        },
        score: {
            width: "7%",
            paddingLeft: 3
        },
        maxScore: {
            width: "5%",
            paddingLeft: 3
        }
    });

    const getAnswerText = (answer: IAnswer|undefined, answerOptionsArr: IAnswerOption[]) => {
        try {
            if (!answer)
                return '';

            for (const answerOption of answerOptionsArr) {
                if (answerOption.id === answer.answerOptionId)
                    return answerOption.name;
            }
        } catch (error) {}
        return '';
    }

    const getAnswerColor = (answer: IAnswer|undefined) => {
        try {
            if (!answer)
                return { color: primaryColor }
            if (answer.answerOptionId === 1)
                return { color: 'green' }
            if (answer.answerOptionId === 2)
                return { color: 'red' }
        } catch (error) {}
        return styles.answer;
    }

    let rowCount = 0;
    const pageBreakRowCount = 18;
    /* Generates a single row in the PDF table */
    const generateRow = (category:ICategory, question:IQuestion) => {
        rowCount++;

    const getRowStyles = () => {
        // First row on page
        if (rowCount % (pageBreakRowCount) === 0) 
            return [styles.row, { borderBottom: "2pt solid #eaeada", paddingTop: 40 }];
        
        // Last row on page
        if (rowCount % (pageBreakRowCount) === pageBreakRowCount - 1) 
            return styles.row;

        // Last row at all
        
        // Anything inbetween
        return [styles.row, { borderBottom: "2pt solid #eaeada" }];
    }

    return(
        <View style={getRowStyles()} break={(rowCount % pageBreakRowCount === 0)} key={category.id + '_' + question.id}>
            <Text style={styles.topic}>{category.name}</Text>
            <Text style={styles.question}>{question.text}</Text>
            <Text style={[styles.answer, getAnswerColor(question.answer)]}>{getAnswerText(question.answer, question.answerOptions)}</Text>
            <Text style={styles.score}>{question.answer?.score}</Text>
            <Text style={styles.score}>{question.maxScore?.toString()}</Text>
        </View>);
    }

    return (
        <Page size="A4" style={styles.page}>
            <View style={styles.textsContainer}></View>
                <View style={styles.tableContainer}>
                    
                    <View style={styles.headerRow} wrap={false}>
                        <Text style={styles.topic}>Topic</Text>
                        <Text style={styles.question}>Question</Text>
                        <Text style={styles.answer}>Answer</Text>
                        <Text style={[styles.score, { color: "black" }]}>Score</Text>
                        <Text style={styles.score}>Max</Text>
                    </View>

                    {props.categories.map((category) => (
                        props.questionsByCategory[category.id].map((question) => (
                            generateRow(category, question)
                        ))
                    ))}
            </View>
        </Page>
    );
}

export default PDFquestionSummaryTable;