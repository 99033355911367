import { useCallback } from "react";
import usePermissionsCheck from "../../../../hooks/usePermissionsCheck";
import { IUser } from "../../../../interfaces";
import {
    CREATE_CLIENT_PERMISSIONS,
    CREATE_ROLES_PERMISSIONS,
    DELETE_CLIENT_PERMISSIONS,
    DELETE_ROLES_PERMISSIONS,
    DELETE_USERS_PERMISSIONS,
    UPDATE_CLIENT_PERMISSIONS,
    UPDATE_ROLES_PERMISSIONS,
    UPDATE_USERS_PERMISSIONS
}
    from "../../../../services/route-guards/config";

const useAdminPermissionsCheck = () => {

    const { hasPermissions } = usePermissionsCheck();

    const hasCreateClientAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(CREATE_CLIENT_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasDeleteClientAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(DELETE_CLIENT_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasUpdateClientAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(UPDATE_CLIENT_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasUpdateUserAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(UPDATE_USERS_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasDeleteUserAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(DELETE_USERS_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasCreateRolesAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(CREATE_ROLES_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasUpdateRolesAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(UPDATE_ROLES_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasDeleteRolesAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(DELETE_ROLES_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    return {
        hasCreateClientAccess,
        hasDeleteClientAccess,
        hasUpdateClientAccess,
        hasUpdateUserAccess,
        hasDeleteUserAccess,
        hasCreateRolesAccess,
        hasUpdateRolesAccess,
        hasDeleteRolesAccess
    };
};

export default useAdminPermissionsCheck;