import React from 'react';

interface IProps {
    title: string;
    section: string;
    summary: any;
    class: string;
}
export default function RecommendationsText(props: IProps) {

    return (!props || !props.summary || !props.summary[props.section])
        ? null
        : (<div className={props.class}>
            <div >
                <h3>{props.title}</h3>
                <div>{props.summary[props.section]}</div>
            </div>
        </div>);
}
