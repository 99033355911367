import React from 'react';
import { IVertical } from '../../../../interfaces';
//Material-UI
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props {
    verticals: IVertical[];
    handleSelect: (arg0: string, arg1: string) => void;
    errorMsg: string;
    validateInputs: () => void;
}

const VerticalsSelection: React.FC<Props> = ({ verticals, handleSelect, errorMsg, validateInputs }) => {
    return (
        <Autocomplete
            className="vertical-autocomplete"
            multiple={false}
            options={verticals}
            disableClearable
            getOptionLabel={(option: IVertical) => option.name}
            onChange={(event: React.ChangeEvent<{}>, value: IVertical) => {
                handleSelect(value.name, 'vertical');
            }}
            onBlur={validateInputs}
            renderInput={(params) => (
                <TextField
                    {...params}
                    classes={{ root: 'preaudit-input__container' }}
                    variant="filled"
                    label="Verticals"
                    color="secondary"
                    error={errorMsg?.length ? true : false}
                    helperText={errorMsg?.length ? errorMsg : null}
                    className={errorMsg?.length ? 'alert-border' : ''}
                />
            )}
        />
    );
};
export default VerticalsSelection;
