import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../Redux/Reducers/accountReducer';
import { NavLink } from 'react-router-dom';
import { store } from '../../../Redux/store';
import { formatForUrlParam } from '../../../utils/stringExtensions';
import { SetCategorySuccess } from '../../../Redux/Actions/client';
import clsx from 'clsx';
import { IPreAudit, IVertical } from '../../../interfaces';
import useStatusDisabled from './hooks/useStatusDisabled';
import useAudit from '../../../hooks/useAudit';

interface OwnProps {
    categories: string[];
    preAuditId: string;
    currentVertical: IVertical;
}

type Props = OwnProps & LinkStateToProps;
const SubNav: React.FC<Props> = ({ categories, preAuditId, actionCategoryId, currentVertical }) => {
    const { statusDisabled } = useStatusDisabled();
    const { auditType } = useAudit();

    const handleClick = useCallback(
        (index: number, e: any) => {
            console.log('subnav', index, actionCategoryId, categories, currentVertical);
            if (statusDisabled(index, actionCategoryId, categories, currentVertical)) {
                e.preventDefault();
            } else {
                store.dispatch(SetCategorySuccess(index + 1));
            }
        },
        [statusDisabled, actionCategoryId, categories, currentVertical]
    );

    return (
        <div className="SubNav">
            <div className="subnav__items">
                {categories.map((c, index) => {
                    let lowerCaseCategory = formatForUrlParam(c);
                    const navItemClass = clsx({
                        subnav__item: true,
                        'subnav__item--disabled': statusDisabled(index, actionCategoryId, categories, currentVertical)
                    });
                    return (
                        <NavLink
                            key={index}
                            exact
                            to={`/audit/${auditType}/${preAuditId}/${lowerCaseCategory}`}
                            onClick={(e) => handleClick(index, e)}
                            className={navItemClass}
                            activeClassName="subnav__item--active"
                        >
                            {c}
                        </NavLink>
                    );
                })}
            </div>
        </div>
    );
};

interface LinkStateToProps {
    currentCategoryId: number;
    actionCategoryId: number;
    preAudits: IPreAudit[];
}

const mapStateToProps = (state: State): LinkStateToProps => ({
    currentCategoryId: state.clientReducer.currentCategoryId,
    actionCategoryId: state.clientReducer.actionCategoryId,
    preAudits: state.clientReducer.preAudits
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(SubNav);
