import React, { useState } from 'react';
import { 
    TextField, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent,
    DialogContentText,
    Grid,
    Select,
    Chip,
    MenuItem,
    IconButton,
     } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as Yup from 'yup';
import { IPermission, IRole } from '../../../../../../interfaces';

const ROLE_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Role name is required'),
    permissions: Yup.array().min(1,'At least one permission is required')
});

export default function ManageRole(props: any) {
    const [role, setRole] = useState<IRole>(props.role);
    const [permissions, setPermissions] = useState<IPermission[]>(
        props.permissions.filter((permission: IPermission) => !props.role.permissions.some((rolePermission: IPermission) => rolePermission.id === permission.id)));
    const [selectedPermission, setSelectedPermission] = React.useState(permissions.length > 0 ? permissions[0].id : '');
    
    const [formValues, setFormValues] = useState({
        name: role.name,
        description: role.description,
        permissions: role.permissions
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState([] as string[]);
    const [permissionsErrorMessage, setPermissionErrorMessage] = useState("");

    const handleConfirm = () => {
        (async function validate() {
            const errors = await ROLE_SCHEMA.validate({ ...formValues }, { abortEarly: false }).catch(function (err) {
                return err;
            });

            if (errors?.errors?.length) {
                setErrors(errors.errors);
                setPermissionErrorMessage(errorFound(errors.errors, 'permission')[0]);
                setFormSubmitted(true);
                return;
            } else {
                props.handleClose();
                props.handleAgree(role);
                setRole(props.role);
            }
        })();
    };

    const handleValueChange = (name: string, value: string) => {
        setRole({
            ...role,
            [name]: value
        });

        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const errorFound = (errors: string[], name: string) => {
        return errors.filter((error: string) => error.includes(name));
    };

    const validateInputs = () => {
        if (formSubmitted) {
            (async function validate() {
                const errors = await ROLE_SCHEMA.validate({ ...formValues }, { abortEarly: false }).catch(function (
                    err
                ) {
                    return err;
                });

                if (errors?.errors?.length) {
                    setErrors(errors.errors);
                    setPermissionErrorMessage(errorFound(errors.errors, 'permission')[0]);
                } else if (errors?.errors === undefined) {
                    setErrors([]);
                    setPermissionErrorMessage('');
                }
            })();
        }
    };

    const handlePermissionAdd = () => {
        const newPermission = permissions.find((p: IPermission) => p.id === selectedPermission);
        var updatedRole = { ...role };
        if(newPermission !== undefined)
            updatedRole.permissions.push(newPermission);
        setRole(updatedRole);
        role.permissions = updatedRole.permissions;
        setFormValues({name: role.name, description: role.description, permissions: role.permissions });

        var updatedPermissions = permissions.filter((p: IPermission) => p.id !== selectedPermission);
        setPermissions(updatedPermissions);
        setSelectedPermission(updatedPermissions.length > 0 ? updatedPermissions[0].id : '');

        validateInputs();
        setPermissionErrorMessage(errorFound(errors, 'permission')[0]);
    };

    const handlePermissionDelete = (permission: IPermission) => () => {
        var updatedRole = { ...role };
        updatedRole.permissions = updatedRole.permissions.filter((p: IPermission) => p.id !== permission.id);
        setRole(updatedRole);
        role.permissions = updatedRole.permissions;

        setFormValues({name: role.name, description: role.description, permissions: role.permissions });

        var updatedPermissions = permissions;
        updatedPermissions.push(permission);
        setPermissions(updatedPermissions);
        setSelectedPermission(updatedPermissions.length > 0 ? updatedPermissions[0].id : '');
        
        validateInputs();
        setPermissionErrorMessage(errorFound(errors, 'permission')[0]);
    };

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedPermission(event.target.value as number);
    };

    return (
        <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className=" dialog"
                maxWidth="md"
            >
                <div>
                    {props.title}
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} className="dialog-divider grid-cell">
                                <DialogContentText className="dialog-content-text">Role Information</DialogContentText>
                                <TextField
                                    classes={{ root: 'preaudit-input__container dialog-input' }}
                                    id="role-name"
                                    label="Role Name"
                                    name="name"
                                    variant="filled"
                                    value={role.name}
                                    onChange={(e) => handleValueChange('name', e.target.value)}
                                    autoComplete="off"
                                    error={errorFound(errors, 'name')[0]?.length ? true : false}
                                    helperText={
                                        errorFound(errors, 'name')[0]?.length ? errorFound(errors, 'name')[0] : null
                                    }
                                    className={errorFound(errors, 'name')[0]?.length ? 'alert-border' : ''}
                                    onBlur={validateInputs}
                                />
                                <TextField
                                    classes={{ root: 'preaudit-input__container dialog-input' }}
                                    id="role-description"
                                    label="Description"
                                    name="description"
                                    variant="filled"
                                    multiline={true}
                                    value={role.description}
                                    onChange={(e) => handleValueChange('description', e.target.value)}
                                    autoComplete="off"
                                    error={errorFound(errors, 'description')[0]?.length ? true : false}
                                    helperText={
                                        errorFound(errors, 'description')[0]?.length ? errorFound(errors, 'description')[0] : null
                                    }
                                    className={errorFound(errors, 'description')[0]?.length ? 'alert-border' : ''}
                                    onBlur={validateInputs}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-cell">
                                <DialogContentText className="dialog-content-subtitle">
                                    Permissions
                                </DialogContentText>
                                {   
                                    role.permissions.length === 0 ? 'This role has no permissions' :
                                    role.permissions.map((permission: IPermission, index: number) => {
                                    return (
                                        <Chip
                                            key={index}
                                            label={permission.command}
                                            className="role--table__body__cell__chip dialog-chip"
                                            onDelete={handlePermissionDelete(permission)}
                                        />
                                    );
                                })}
                                {permissionsErrorMessage && <p className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error'>{permissionsErrorMessage}</p>}
                                <DialogContentText className="dialog-content-text  margin-top">
                                    Add Permission
                                </DialogContentText>
                                <Select
                                    className="dialog-permissions-select"
                                    onChange={handleSelectChange}
                                    value={selectedPermission}
                                >
                                    {permissions.map((permission: IPermission, index: number) => {
                                        return (
                                            <MenuItem key={index} value={permission.id}>
                                                {permission.command}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <IconButton aria-label="add role" onClick={handlePermissionAdd} disabled={permissions.length === 0}>
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="btns-container">
                        <Button className="btn btn_basic" onClick={props.handleClose}>
                            Cancel
                        </Button>
                        <Button className="btn btn_basic" onClick={() => handleConfirm()} autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
    );
}