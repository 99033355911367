import React, { FC, useEffect, useState, Suspense } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getPreAuditSummary } from '../../../services/preAuditService';
import { ISummary, ICategoryRecommendation } from '../../../interfaces';

import ReportButtons from './components/ReportButtons';
import RecommendationsText from './components/RecommendationText';
import CategoryRecommendationText from './components/CategoryRecommendationText';
import PDF from './components/PDFdoc';
import { Loader } from '../../Loader';
import { Paper, Button } from '@material-ui/core';
import { getAuditLabel } from '../../../utils/summaryExtensions';
import ChartContainer from './containers/ChartContainer/ChartContainer';
import useSummary from './hooks/useSummary';
import useAudit from '../../../hooks/useAudit';
import QuestionsSummary from '../preAuditSummary/QuestionsSummary';

interface IUser {
    id: number;
    email: string;
}

interface ParamTypes {
    preAuditId: string;
}

const PreAuditReport: FC = () => {
    const { preAuditId } = useParams<ParamTypes>();
    const [summary, setSummary] = useState<ISummary>({} as ISummary);
    const [user, setUser] = useState<IUser>({} as IUser);
    const [chart, setChart] = useState<string>('');
    const [imgReady, setImgReady] = useState(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const [auditLabel, setAuditLabel] = useState('');

    const { getTotalScore } = useSummary();
    const { auditType } = useAudit();

    useEffect(() => {
        let isFetching = true;
        (async function () {
            const auditSummary = await getPreAuditSummary(+preAuditId);
            if (isFetching) {
                setSummary(auditSummary);
                setUser(auditSummary.consultant);
            }
        })();
        return () => {
            isFetching = false;
        };
    }, [preAuditId, setSummary]);

    useEffect(() => { setAuditLabel(getAuditLabel(summary)); }, [summary]);

    var utc = new Date().toJSON().slice(0, 10);
    let Consultant: string | undefined = getName();
    function getName() {
        let name;
        if (user.email) {
            const namearray = user.email.substr(0, user.email.indexOf('@')).split('.');
            const firstname = namearray[0].charAt(0).toUpperCase() + namearray[0].slice(1);
            const lastname = namearray[1].charAt(0).toUpperCase() + namearray[1].slice(1);
            name = firstname + ' ' + lastname;
        }
        return name;
    }

    function handleComplete() {
        setCompleted(true);
    }

    function setChartImage(arg: any) {
        setChart(arg);
        setImgReady(true);
    }

    return (
        <>
            {summary?.vertical && user.email && Consultant?.length && summary && summary.scores ? (
                <Suspense fallback={<Loader />}>
                    <div className="report__page">
                        <div className="report__container--pdf">
                            <Paper className="report--main">
                                <div className="report--header">
                                    <img src={require('../../../assets/images/dentsu logo_white.png')} alt="Logo" />
                                    <div className="report--header--text">
                                        <div>
                                            <b>Client:</b>&nbsp;
                                            {summary.client.name.charAt(0).toUpperCase() + summary.client.name.slice(1)}
                                        </div>
                                        <div>
                                            <b>Date:</b>&nbsp;{utc}
                                        </div>
                                        <div>
                                            <b>Vertical:</b>&nbsp;{summary.vertical.name}
                                        </div>
                                        <div>
                                            <b>Consultant:</b>&nbsp;{Consultant}
                                        </div>
                                        <div>
                                            <b>Contact:</b>&nbsp;<a href={`mailto:${user.email}`}>{user.email}</a>
                                        </div>
                                    </div>
                                    {/* </Card> */}
                                </div>
                                <ChartContainer
                                    summary={summary}
                                    setChart={setChartImage}
                                />
                                <div className="report--body__texts">
                                    <RecommendationsText
                                        class="report--body--text"
                                        title="Summary"
                                        section="summary"
                                        summary={summary}
                                    />
                                    <RecommendationsText
                                        class="report--body--text"
                                        title="Recommendations"
                                        section="recommendations"
                                        summary={summary}
                                    />

                                    {summary.categoryRecommendations.map((categoryRecommendation: ICategoryRecommendation, qindex: number) => {
                                        return (
                                            <CategoryRecommendationText
                                                key={qindex}
                                                class="report--body--text"
                                                category={categoryRecommendation.categoryName}
                                                recommendation={categoryRecommendation.recommendation}
                                    />
                                        );
                                    })}

                                    <RecommendationsText
                                        class="report--body--text"
                                        title="Follow-Up"
                                        section="followUp"
                                        summary={summary}
                                    />
                                    <RecommendationsText
                                        class="report--body--text"
                                        title="Full-Audit"
                                        section="fullAudit"
                                        summary={summary}
                                    />
                                </div>
                                <div className="preAudit-Summary--container">
                                    <div className="inReport">
                                        <h3>Questions Summary</h3>
                                        <QuestionsSummary categories={summary.vertical.categories} auditType={summary.auditType} />
                                    </div>
                                </div>
                            </Paper>
                        </div>
                        <div className="report__form__section">
                            <h1>Your Report</h1>
                            {completed || summary.status === 2 ? (
                                <p>
                                    This {auditLabel} is completed. Download the PDF file or email it using the buttons below.
                                </p>
                            ) : (
                                <>
                                    <p>
                                        Please check the content on the left side.
                                        Do you need to make any changes? Click the 'Back' button.
                                        No changes needed? Click the 'Complete Audit'.
                                    </p>
                                    <p>
                                        <span>Note: after completing the Audit you won't be able to make any more changes!</span>
                                    </p>
                                </>
                            )}

                            <div className="report--buttons">
                                {completed || summary.status === 2 ? (
                                    <ReportButtons completed={completed} summary={summary} preAuditId={+preAuditId} />
                                ) : (
                                    <Link to={`/audit/${auditType}/${preAuditId}/summary`}>
                                        <Button className="btn btn_outline" variant="contained">
                                            Back
                                        </Button>
                                    </Link>
                                )}
                                {chart.length > 0 && imgReady && (
                                    <div className="report--buttons__CTA">
                                        <PDF
                                            email={user.email}
                                            consultant={Consultant}
                                            summary={summary}
                                            totalScore={getTotalScore(summary)}
                                            chartImg={chart}
                                            completed={completed}
                                            handleSetComplete={handleComplete}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Suspense>
            ) : (
                <Loader />
            )}
        </>
    );
};

export default PreAuditReport;
