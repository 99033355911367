import React from "react";
import { ISummary } from "../../../../../interfaces";
import useAuditTypeChartComponent from "../../hooks/useAuditTypeChartComponent";

interface Props {
    summary: ISummary;
    setChart: any;
}

const ChartContainer = ({ summary, setChart }: Props) => {
    const { ChartComponent } = useAuditTypeChartComponent();
    return <ChartComponent summary={summary} setChart={setChart} />;
}

export default ChartContainer;