import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { IRole } from '../../../../../../interfaces';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Chip,
    Select,
    MenuItem,
    Grid
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { ButtonTooltip } from "../../../../../ButtonTooltip";

const USER_SCHEMA = Yup.object().shape({
    name: Yup.string().required('User name is required'),
    email: Yup.string().required('User email is required')
});

export default function UpdateUser(props: any) {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState(props.user);
    const [roles, setRoles] = useState(
        props.roles.filter((role: IRole) => !props.user.roles.some((userRole: IRole) => userRole.id === role.id))
    );
    const [selectedRole, setSelectedRole] = React.useState(roles.length > 0 ? roles[0].id : '');

    const [formValues, setFormValues] = useState({
        name: user.name,
        email: user.email
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState([] as string[]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        (async function validate() {
            const errors = await USER_SCHEMA.validate({ ...formValues }, { abortEarly: false }).catch(function (err) {
                return err;
            });

            if (errors?.errors?.length) {
                setErrors(errors.errors);
                setFormSubmitted(true);
                return;
            } else {
                handleClose();
                props.handleAgree(user);
            }
        })();
    };

    const handleValueChange = (name: string, value: string) => {
        setUser({
            ...user,
            [name]: value
        });

        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const errorFound = (errors: string[], name: string) => {
        return errors.filter((error: string) => error.includes(name));
    };

    const validateInputs = () => {
        if (formSubmitted) {
            (async function validate() {
                const errors = await USER_SCHEMA.validate({ ...formValues }, { abortEarly: false }).catch(function (
                    err
                ) {
                    return err;
                });
                if (errors?.errors?.length) {
                    setErrors(errors.errors);
                } else if (errors?.errors === undefined) {
                    setErrors([]);
                }
            })();
        }
    };

    const handleDelete = (role: IRole) => () => {
        var updatedUser = { ...user };
        updatedUser.roles = updatedUser.roles.filter((r: IRole) => r.id !== role.id);
        setUser(updatedUser);

        var updatedRoles = roles;
        updatedRoles.push(role);
        setRoles(updatedRoles);
        setSelectedRole(updatedRoles.length > 0 ? updatedRoles[0].id : '');
    };

    const handleRoleAdd = () => {
        const newRole = roles.find((r: IRole) => r.id === selectedRole);
        var updatedUser = { ...user };
        updatedUser.roles.push(newRole);
        setUser(updatedUser);

        var updatedRoles = roles.filter((r: IRole) => r.id !== selectedRole);
        setRoles(updatedRoles);
        setSelectedRole(updatedRoles.length > 0 ? updatedRoles[0].id : '');
    };

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedRole(event.target.value as number);
    };

    return (
        <div>
            <ButtonTooltip
                tooltipTitle={'Update user'}
                isDisabled={props.disabled}
                titleDisabled={props.disabled ? 'Not enough permissions' : 'Update user'}
            >
                <IconButton disabled={props.disabled} color="secondary" onClick={() => handleClickOpen()}>
                    <EditIcon className="user--table__body__cell__edit" />
                </IconButton>
            </ButtonTooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className=" dialog"
            >
                <div>
                    <DialogTitle className="dialog-title">
                        <div className="add-client-title">
                            <EditIcon />
                            <h2>Edit Client</h2>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} className="dialog-divider grid-cell">
                                <DialogContentText className="dialog-content-text">User Information</DialogContentText>
                                <TextField
                                    classes={{ root: 'preaudit-input__container dialog-input' }}
                                    id="user-name"
                                    label="User Name"
                                    name="name"
                                    variant="filled"
                                    value={user.name}
                                    onChange={(e) => handleValueChange('name', e.target.value)}
                                    autoComplete="off"
                                    error={errorFound(errors, 'name')[0]?.length ? true : false}
                                    helperText={
                                        errorFound(errors, 'name')[0]?.length ? errorFound(errors, 'name')[0] : null
                                    }
                                    className={errorFound(errors, 'name')[0]?.length ? 'alert-border' : ''}
                                    onBlur={validateInputs}
                                />
                                <TextField
                                    classes={{ root: 'preaudit-input__container dialog-input' }}
                                    id="user-email"
                                    label="Email"
                                    name="email"
                                    variant="filled"
                                    value={user.email}
                                    onChange={(e) => handleValueChange('email', e.target.value)}
                                    autoComplete="off"
                                    error={errorFound(errors, 'email')[0]?.length ? true : false}
                                    helperText={
                                        errorFound(errors, 'email')[0]?.length ? errorFound(errors, 'email')[0] : null
                                    }
                                    className={errorFound(errors, 'email')[0]?.length ? 'alert-border' : ''}
                                    onBlur={validateInputs}
                                />
                            </Grid>
                            <Grid item xs={6} className="grid-cell">
                                <DialogContentText className="dialog-content-subtitle">
                                    Roles <Link to="/admin/roles">Manage roles</Link>
                                </DialogContentText>

                                {user.roles.map((role: IRole, index: number) => {
                                    return (
                                        <Chip
                                            key={index}
                                            label={role.name}
                                            className="user--table__body__cell__chip dialog-chip"
                                            onDelete={handleDelete(role)}
                                        />
                                    );
                                })}
                                <DialogContentText className="dialog-content-text  margin-top">
                                    Add Role
                                </DialogContentText>
                                <Select
                                    className="dialog-roles-select"
                                    onChange={handleSelectChange}
                                    value={selectedRole}
                                >
                                    {roles.map((role: IRole, index: number) => {
                                        return (
                                            <MenuItem key={index} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <IconButton aria-label="add role" onClick={handleRoleAdd} disabled={roles.length === 0}>
                                    <AddIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="btns-container">
                        <Button className="btn btn_basic" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button className="btn btn_basic" onClick={() => handleConfirm()} autoFocus>
                            Save
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
