import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState, store } from '../../../Redux/store';
import { useHistory, useParams } from 'react-router-dom';
import { ICountry, IPreAudit, IVertical, IClient, IVerticalCategory } from '../../../interfaces';
import { AuditType, IAuditType } from '../../../interfaces/audittype';
import * as Yup from 'yup';
import { GetCategoryActionSuccess } from '../../../Redux/Actions/client';
import { formatForUrlParam } from '../../../utils/stringExtensions';
import { Button, TextField, FormControlLabel, Switch, FormControl, Grid } from '@material-ui/core';
import { createPreAudit, setSelectedVertical, setSelectedClient } from '../../../services/userService';
import VerticalsSelection from './components/VerticalsSelection';
import ClientsSelection from './components/ClientsSelection';
import VerticalsDescription from './components/VerticalsDescription';
import SelectCountries from './components/SelectCountries';
import { Loader } from '../../Loader';
import { getAuditTypeById } from '../../../services/fetchAuditTypes';

interface auditTypeParam {
    auditType: string;
}

const PREAUDIT_SCHEMA = Yup.object().shape({
    vertical: Yup.string().required('Vertical field is required'),
    countries: Yup.array<any>().test({
        message: 'Country field is required',
        test: (arr: unknown) => {
            const testArr = arr as Array<any>;
            return testArr.length !== 0;
        }
    }),
    client: Yup.string().required('Client field is required'),
    showUrl: Yup.boolean(),
    url: Yup.string().when('showUrl', {
        is: true,
        then: Yup.string().url().required('URL field is required'),
        otherwise: Yup.string().notRequired()
    })
});

type Props = LinkStateToProps;

const CreatePreAudits: React.FC<Props> = ({ verticals, clients }) => {
    const [formValues, setFormValues] = useState({
        vertical: '' as string,
        countries: [{ id: 214, name: 'Global', code: 'GL' }] as ICountry[],
        client: '' as string,
        url: '' as string
    });
    const [errors, setErrors] = useState([] as string[]);
    const [loader, setLoader] = useState(false as boolean);
    const [global, setGlobal] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const { auditType } = useParams<auditTypeParam>();
    const history = useHistory();
    const [currentAuditType, setCurrentAuditType] = useState<IAuditType>({
        name: '',
        id: 0,
        description: ''
    });

    const handleChange = (value: string, name: string) => {
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const toggleGlobal = () => {
        const newErrors = errors.filter((error) => !error.includes('Country'));
        setErrors(newErrors);
        setGlobal((prev) => !prev);
        let globalState = !global ? [{ id: 214, name: 'Global', code: 'GL' }] : [];
        setFormValues({
            ...formValues,
            countries: globalState
        });
    };
    const errorFound = (errors: string[], name: string) => {
        return errors.filter((error: string) => error.includes(name));
    };

    const auditTypeNumber = Number(auditType);

    const handleSubmit = () => {
        (async function validate() {
            const errors = await PREAUDIT_SCHEMA.validate(
                { ...formValues, showUrl: auditTypeNumber === AuditType.CROPreAudit ? true : false },
                { abortEarly: false }
            ).catch(function (err) {
                return err;
            });

            if (errors?.errors?.length) {
                setErrors(errors.errors);
                setFormSubmitted(true);
                return;
            }
            const countriesIds = formValues.countries.map((country: ICountry) => ({ id: country.id }));
            const vertical = verticals.filter((vertical: IVertical) => vertical.name === formValues.vertical)[0];
            const client = clients.filter((client: IClient) => client.name === formValues.client)[0];

            vertical.categories = vertical.categories.filter(
                (cat: IVerticalCategory) => cat.auditType.id === currentAuditType?.id
            );

            if (vertical.categories.length === 0) {
                setErrors(['There were no categories defined for vertical ' + vertical.name]);
                setFormSubmitted(true);
                return;
            }
            setLoader(true);
            setErrors([]);
            const data = {
                client: { id: client.id },
                url: formValues.url,
                vertical: { id: vertical.id },
                countries: countriesIds,
                auditType: {
                    id: currentAuditType?.id,
                    name: currentAuditType?.name,
                    description: currentAuditType?.description
                }
            };
            store.dispatch(GetCategoryActionSuccess(0));
            store.dispatch(setSelectedVertical(vertical));
            store.dispatch(setSelectedClient(client));
            const preAuditId = await store.dispatch(createPreAudit(data));
            if (preAuditId) {
                history.push(`/audit/${auditType}/${preAuditId}/${formatForUrlParam(vertical.categories[0].name)}`);
            }
        })();
    };

    const validateInputs = () => {
        if (formSubmitted) {
            (async function validate() {
                const errors = await PREAUDIT_SCHEMA.validate(
                    { ...formValues, showUrl: auditTypeNumber === AuditType.CROPreAudit ? true : false },
                    { abortEarly: false }
                ).catch(function (err) {
                    return err;
                });
                if (errors?.errors?.length) {
                    setErrors(errors.errors);
                } else if (errors?.errors === undefined) {
                    setErrors([]);
                }
            })();
        }
    };

    const getInstructions = () => {
        if (currentAuditType !== null || currentAuditType) {
            return (
                <div className="instructionsList" dangerouslySetInnerHTML={{ __html: currentAuditType.description }} />
            );
        }
        return;
    };

    useEffect(() => {
        (async () => {
            let auditTypeIdAsInt = Number.parseInt(auditType);
            if (!auditTypeIdAsInt) {
                auditTypeIdAsInt = 1;
            }
            const currentAuditTypeById = await getAuditTypeById(auditTypeIdAsInt);
            setCurrentAuditType(currentAuditTypeById);
        })();
    }, [auditType]);

    return (
        <div className="create-preaudit__page">
            <div className="create-preaudit__info-section">
                <div className="create-preaudit__info-section__content">
                    {getInstructions()}
                    <VerticalsDescription state={formValues} verticals={verticals} auditTypeId={auditType} />
                </div>
            </div>
            <div className="create-preaudit__form-section">
                <h1>
                    {auditTypeNumber === AuditType.iActivate
                        ? 'Create iActivate Audit'
                        : auditTypeNumber === AuditType.CROMaturity
                        ? 'Create CRO Maturity Audit'
                        : 'Create Pre-Audit'}
                </h1>
                <h3 className="instructionsList__title">Client Information</h3>
                <div
                    className={
                        auditTypeNumber === AuditType.CROPreAudit
                            ? 'createPreAudit_inputs'
                            : 'createPreAudit_inputs_nourl'
                    }
                >
                    <ClientsSelection
                        handleSelect={handleChange}
                        clients={clients.filter((f: IClient) => f.isDeleted !== true)}
                        errorMsg={errorFound(errors, 'Client')[0]}
                        validateInputs={validateInputs}
                    />
                    <VerticalsSelection
                        handleSelect={handleChange}
                        verticals={verticals}
                        errorMsg={errorFound(errors, 'Vertical')[0]}
                        validateInputs={validateInputs}
                    />
                    {auditTypeNumber === AuditType.CROPreAudit && (
                        <TextField
                            classes={{ root: 'preaudit-input__container client-url__input' }}
                            id="client-url"
                            label="URL"
                            name="url"
                            variant="filled"
                            autoComplete="off"
                            error={errorFound(errors, 'URL')[0]?.length ? true : false}
                            helperText={errorFound(errors, 'URL')[0]?.length ? errorFound(errors, 'URL')[0] : null}
                            className={errorFound(errors, 'URL')[0]?.length ? 'alert-border' : ''}
                            onChange={(e) => handleChange(e.target.value, e.target.name)}
                            onBlur={validateInputs}
                        />
                    )}

                    <h3 className="countries__title">Countries</h3>
                    <Grid className="countries__label" component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                            <FormControlLabel
                                classes={{ root: 'global__switcher' }}
                                control={
                                    <Switch color="primary" checked={global} onChange={toggleGlobal} name="checkedC" />
                                }
                                label={false}
                                id="global"
                            />
                        </Grid>
                        <Grid item className="global-switch__option">
                            Global
                        </Grid>
                    </Grid>
                    <FormControl className="select-country__autocomplete__container">
                        <SelectCountries
                            active={global}
                            state={formValues}
                            handleSelect={handleChange}
                            errorMsg={errorFound(errors, 'Country')[0]}
                            validateInputs={validateInputs}
                        />
                    </FormControl>
                </div>
                {loader ? (
                    <Loader />
                ) : (
                    <Button className="btn btn_highlight" variant="contained" color="secondary" onClick={handleSubmit}>
                        Start Audit
                    </Button>
                )}
            </div>
        </div>
    );
};

interface LinkStateToProps {
    verticals: IVertical[];
    countries: ICountry[];
    preAuditId: IPreAudit[];
    clients: IClient[];
}
const mapStateToProps = (state: AppState): LinkStateToProps => ({
    verticals: state.clientReducer.verticals,
    countries: state.clientReducer.countries,
    preAuditId: state.clientReducer.preAuditId,
    clients: state.adminReducer.clients
});

export default connect(mapStateToProps)(CreatePreAudits);
