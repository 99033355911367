import React, { useEffect, useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { IBasicMenu } from '../../interfaces/basicMenu';
import { Link } from 'react-router-dom';
import { getAuditTypes } from '../../services/fetchAuditTypes';

interface audit {
    id: number;
    name: string;
    description: string;
}

export default function BasicMenu({ id, anchorEl, open, handleClose }: IBasicMenu) {
    const [auditTypes, setAuditTypes] = useState<audit[]>([]);

    useEffect(() => {
        (async () => {
            const response = await getAuditTypes();
            setAuditTypes(response);
        })();
    }, []);

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button'
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            disableAutoFocusItem={true}
        >
            {auditTypes.map((type) => {
                return (
                    <MenuItem key={type.id} onClick={handleClose}>
                        <Link to={`/audit/create/${type.id}`} style={{ color: '#000000' }}>
                            {type.name}
                        </Link>
                    </MenuItem>
                );
            })}
        </Menu>
    );
}
