import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { UserActionTypes } from './Actions/account';
import { userReducer } from './Reducers/accountReducer';
import { clientReducer } from './Reducers/clientReducer';
import { ClientActionTypes } from './Actions/client';
import { routerReducer } from './Reducers/routerReducer';
import { AdminActionTypes } from './Actions/admin';
import { adminReducer } from './Reducers/adminReducers';


export type AppActions = UserActionTypes | ClientActionTypes | AdminActionTypes;

export const rootReducer = combineReducers({
    accountReducer: userReducer,
    clientReducer: clientReducer,
    routerReducer: routerReducer,
    adminReducer: adminReducer
    
});

export type AppState = ReturnType<typeof rootReducer>;
export const store = createStore(rootReducer, applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>));
