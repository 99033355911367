import * as http from './http';
import { ApiBaseUrl } from '../config';
import { saveAs } from 'file-saver';
import { INotes, IAnswer } from '../interfaces';
import { StartGetCategoryActionSuccess } from '../Redux/Actions/client';
import { store } from '../Redux/store';
import { ISummary } from '../interfaces/preauditSummary';
import { IRecommendations } from '../interfaces/recommendations';

export async function getNotes(preAuditId: number): Promise<string> {
    // store.dispatch(NotesActions.getNotesRequest());
    try {
        const result = await http.GET<string>(`${ApiBaseUrl}/preaudits/getnotes?preAuditId=${preAuditId}`);
        // store.dispatch(NotesActions.getNotesSuccess(result));
        return result;
    } catch (error) {
        throw error;
        // store.dispatch(NotesActions.getNotesFailure());
    }
}

export async function putNotes(preAuditId: number, notes: string): Promise<void> {
    try {
        await http.PUT<INotes>(`${ApiBaseUrl}/preaudits/notes`, { preauditid: preAuditId, notes });
        // store.dispatch(NotesActions.getNotesSuccess(result));
    } catch (error) {
        throw error;
        // store.dispatch(NotesActions.getNotesFailure());
    }
}

export async function postAnswer(answer: IAnswer) {
    const { preAuditId, questionId, score, answerOptionId } = answer;
    try {
        await http.POST<INotes>(`${ApiBaseUrl}/preaudits/answer`, {
            preauditid: preAuditId,
            questionid: questionId,
            score,
            answeroptionid: answerOptionId
        });
    } catch (error) { }
}

export async function getActionCategory(preAuditId: number) {
    try {
        const result = await http.GET<any>(`${ApiBaseUrl}/preaudits/${preAuditId}/actioncategory`);
        store.dispatch(StartGetCategoryActionSuccess(result.id));
    } catch (error) { }
}

export async function getPreAuditStatus(preAuditId: number) {
    try {
        const result = await http.GET<any>(`${ApiBaseUrl}/preaudits/getstatus/${preAuditId}`);
        return result;
    } catch (error) {
        if (error.message.includes('404')) {
            return 404;
        } else {
        }
    }
}

//Summary Page
export async function getPreAuditSummary(preAuditId: number) {
    try {
        const result = await http.GET<ISummary>(`${ApiBaseUrl}/preaudits/summary/${preAuditId}`);
        return result;
    } catch (error) {
        throw error;
    }
}
export async function getRecommendationsSummary(preAuditId: number) {
    try {
        const result = await http.GET<IRecommendations>(
            `${ApiBaseUrl}/preaudits/summary/${preAuditId}/recommendations`
        );
        return result;
    } catch (error) { }
}
export async function putBenchmark(preAuditId: number, categoryId: number, value: number) {
    try {
        await http.PUT<any>(
            `${ApiBaseUrl}/preaudits/benchmark?preauditid=${preAuditId}&categoryid=${categoryId}&benchmark=${value}`
        );
    } catch (error) { }
}
export async function putTarget(preAuditId: number, categoryId: number, value: number) {
    try {
        await http.PUT<any>(
            `${ApiBaseUrl}/preaudits/target?preauditid=${preAuditId}&categoryid=${categoryId}&benchmark=${value}`
        );
    } catch (error) { }
}
export async function putText(preauditid: number, text: string, section: string) {
    try {
        const result = await http.PUT<any>(`${ApiBaseUrl}/preaudits/${section}`, {
            preauditid: preauditid,
            message: text
        });

        return result;
    } catch (error) { }
}

export async function putCategoryMessage(preauditid: number, categoryid: number, message: string) {
    try {
        const result = await http.PUT<any>(`${ApiBaseUrl}/preaudits/categorymessage`, {
            preauditid: preauditid,
            categoryid: categoryid,
            message: message
        });

        return result;
    } catch (error) { }
}


//Report Page
export async function getChart(data: string) {
    try {
        const result = await http.POST<any>(`${ApiBaseUrl}/preaudits/summary/chart`, {
            imagedata: data
        });

        return result;
    } catch (error) { }
}
export async function completeAudit(data: any) {
    try {
        const result = await http.POST<any>(`${ApiBaseUrl}/preaudits/complete`, data);
        return result;
    } catch (error) { }
}
export async function sendPDF(data: any) {
    try {
        await http.POST<INotes>(`${ApiBaseUrl}/preaudits/sendreport`, data);
    } catch (error) { }
}

export async function handleGetPDF(client: any, preAuditId: any) {
    try {
        const result = await http.GET<any>(`${ApiBaseUrl}/preaudits/getreport/${preAuditId}`);
        base64toBlob(result, '', client);
    } catch (error) { }
}

function base64toBlob(base64Data: any, contentType: any, client: string) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);
        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const newPdf = new Blob(byteArrays, { type: contentType });
    saveAs(newPdf, `dentsu-Audit_${client}.pdf`);
}

