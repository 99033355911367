import { UserActionTypes, SET_USER } from '../Actions/account';

export interface State {
    accountReducer: any;
    clientReducer: any;
}
const initialState: any = {
    accountUser: undefined
};

export const userReducer = (state = initialState, action: UserActionTypes): any => {
    switch (action.type) {
        case SET_USER:
            return {
                accountUser: action.payload
            };
        default:
            return {
                ...state
            };
    }
};
