import * as http from './http';
import { ApiBaseUrl } from '../config';
import {
    GET_ROLES_REQUEST,
    GET_ROLES_FAILURE,
    StartGetRolesSuccess,
    GET_PERMISSIONS_REQUEST,
    GET_PERMISSIONS_FAILURE,
    StartGetPermissionsSuccess
} from '../Redux/Actions/admin';
import { store, AppState } from '../Redux/store';
import { ThunkAction } from 'redux-thunk';
import { IRole } from '../interfaces';

export const LoadRoles = (): ThunkAction<void, AppState, undefined, any> => async (dispatch, getState) => {
    dispatch({
        type: GET_ROLES_REQUEST
    });
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/roles`);
        store.dispatch(StartGetRolesSuccess(response));
    } catch (e) {
        dispatch({
            type: GET_ROLES_FAILURE,
            error: 'Failed to load roles'
        });
    }
};

export async function deleteRole(roleId: number) {
    try {
        await http.DELETE<any>(`${ApiBaseUrl}/roles/${roleId}`);
    } catch (error) {}
}

export const LoadPermissions = (): ThunkAction<void, AppState, undefined, any> => async (dispatch, getState) => {
    dispatch({
        type: GET_PERMISSIONS_REQUEST
    });
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/roles/permissions`);
        store.dispatch(StartGetPermissionsSuccess(response));
    } catch (e) {
        dispatch({
            type: GET_PERMISSIONS_FAILURE,
            error: 'Failed to load permissions'
        });
    }
};

export async function postRole(role: IRole) {
    try {
        await http.POST<IRole>(`${ApiBaseUrl}/roles`, {
            id: role.id,
            name: role.name,
            description: role.description,
            permissions: role.permissions
        });
    } catch (error) {
        console.log(error);
    }
}

export async function putRole(role: IRole) {
    try {
        await http.PUT<IRole>(`${ApiBaseUrl}/roles`, {
            id: role.id,
            name: role.name,
            description: role.description,
            permissions: role.permissions
        });
    } catch (error) {
        console.log(error);
    }
}
