import { INotes } from './../../interfaces';

export const Type = {
    // GET NOTES
    GET_NOTES_REQUEST: 'GET_NOTES_REQUEST',
    GET_NOTES_SUCCESS: 'GET_NOTES_SUCCESS',
    GET_NOTES_FAILURE: 'GET_NOTES_FAILURE'
};

// GET NOTES

export function getNotesRequest() {
    return {
        type: Type.GET_NOTES_REQUEST
    };
}

export function getNotesSuccess(notes: INotes) {
    return {
        type: Type.GET_NOTES_SUCCESS,
        payload: notes
    };
}

export function getNotesFailure() {
    return {
        type: Type.GET_NOTES_REQUEST
    };
}
