import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { sendPDF } from '../../../../services/preAuditService';
import { useParams } from 'react-router-dom';
//Material-UI
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, Typography } from '@material-ui/core';

const Mail_SCHEMA = Yup.object().shape({
    subject: Yup.string().required('Subject field is required'),
    body: Yup.string().required('Message field is required')
});

interface ParamTypes {
    preAuditId: string
}

export default function MailModal(props: any) {
    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState([] as string[]);
    const [mailError, setMailError] = useState('' as string);
    const [errors, setErrors] = useState([] as string[]);
    const [state, setState] = useState({
        preauditid: 0 as number,
        to: '' as string,
        subject: '' as string,
        body: '' as string
    });
    const { preAuditId } = useParams<ParamTypes>();
    useEffect(() => {
        setOpen(props.showModal);
    }, [props.showModal]);

    const handleClose = () => {
        props.setShowModal(false);
    };
    let data = {
        preauditid: preAuditId,
        to: items,
        subject: state.subject,
        body: state.body,
        reportname: props.reportName
    };

    const handleKeyDown = (evt: any) => {
        if (['Enter', 'Tab', ','].includes(evt.key)) {
            evt.preventDefault();
            var value = evt.target.value.trim();

            if (value && isValid(value)) {
                setItems([...items, value]);
            }
            evt.target.value = '';
        }
    };
    const handleBlur = (evt: any) => {
        evt.preventDefault();
        var value = evt.target.value.trim();

        if (value && isValid(value)) {
            setItems([...items, value]);
        }
        evt.target.value = '';
    };

    const handleDelete = (item: any) => {
        setItems(items.filter((i) => i !== item));
    };

    function isValid(email: string) {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }
        if (!email.length) {
            error = `Please type something first`;
        }
        if (error) {
            setMailError(error);

            return false;
        }
        setMailError('');
        return true;
    }

    function isInList(email: string) {
        return items.includes(email);
    }

    function isEmail(email: string) {
        // eslint-disable-next-line no-useless-escape
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    const handleChange = (value: string, name: string) => {
        setState({
            ...state,
            [name]: value
        });
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        (async function validate() {
            const errors = await Mail_SCHEMA.validate(state, { abortEarly: false }).catch(function (err) {
                return err;
            });
            if (errors?.errors?.length) {
                setErrors(errors.errors);
            } else if (items.length < 1) {
                setErrors(['email required']);
            } else {
                setErrors([]);
                sendPDF(data);
                props.setShowModal(false);
            }
        })();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="mail-dialog-title"
            aria-describedby="mail-dialog-description"
        >
            <DialogTitle id="mail-dialog-title">{'Please provide the following information:'}</DialogTitle>
            <DialogContent>
                {items?.map((item) => (
                    <div className="tag-item" key={item}>
                        {item}
                        <button type="button" className="button" onClick={() => handleDelete(item)}>
                            &times;
                            </button>
                    </div>
                ))}
                <TextField
                    margin="dense"
                    id="mail"
                    label="Email Address"
                    type="email"
                    name="to"
                    placeholder="Type or paste email addresses and press `Enter`..."
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    fullWidth
                    required
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                />
                <TextField
                    margin="dense"
                    id="subject"
                    label="Subject"
                    type="string"
                    name="subject"
                    fullWidth
                    required
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                />
                <TextField
                    margin="dense"
                    id="message"
                    name="body"
                    label="message"
                    type="string"
                    fullWidth
                    multiline
                    rows={4}
                    required
                    onChange={(e) => handleChange(e.target.value, e.target.name)}
                />
                {errors.length > 0 &&
                    errors.map((e, index) => (
                        <Typography key={index} variant="body2" color="error">
                            {e}
                        </Typography>
                    ))}
                {mailError && (
                    <Typography className="error" variant="body2" color="error">
                        {mailError}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button className="btn btn_outline--gray" onClick={handleClose}>
                    Close
                    </Button>
                <Button className="btn btn_highlight" onClick={handleSubmit}>
                    Send
                    </Button>
            </DialogActions>
        </Dialog>

    );
}
