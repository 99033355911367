import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ManageClient from './ManageClient';
import { ButtonTooltip } from '../../../../../ButtonTooltip';

export default function UpdateClient(props: any) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <div className='dialog'>
            <ButtonTooltip
                tooltipTitle={'Update client'}
                isDisabled={props.disabled}
                titleDisabled={props.disabled ? 'Not enough permissions' : 'Update client'}
            >
                <IconButton disabled={props.disabled} color="secondary" onClick={() => handleClickOpen()}>
                    <EditIcon />
                </IconButton>
            </ButtonTooltip>
            {props.disabled ? (
                ''
            ) : (
                <ManageClient
                    title={
                        <div className="add-client-title">
                            <EditIcon />
                            <h2>Edit Client</h2>
                        </div>
                    }
                    open={open}
                    client={props.client}
                    handleAgree={props.handleAgree}
                    handleClose={handleClose}
                />
            )}
        </div>
    );
}
