import * as http from './http';
import { ApiBaseUrl } from '../config';
import {
    GET_VERTICALS_REQUEST,
    GET_VERTICALS_FAILURE,
    StartGetVerticalsSuccess,
    GET_COUNTRIES_REQUEST,
    StartGetCountriesSuccess,
    GET_COUNTRIES_FAILURE,
    GetPreAuditsRequest,
    GetPreAuditsSuccess,
    GetPreAuditsFailure
} from '../Redux/Actions/client';
import { store, AppState } from '../Redux/store';
import { ThunkAction } from 'redux-thunk';

export const LoadPreAudits = async () => {
    store.dispatch(GetPreAuditsRequest());
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/preaudits`);
        store.dispatch(GetPreAuditsSuccess(response));
    } catch (e) {
        store.dispatch(GetPreAuditsFailure());
    }
};

export const LoadCurrentPreAudit = async (preauditId: number) => {
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/preaudits/${preauditId}`);
        return response;
    } catch (e) {}
};

export const LoadCountries = (): ThunkAction<void, AppState, undefined, any> => async (dispatch, getState) => {
    dispatch({
        type: GET_COUNTRIES_REQUEST
    });
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/countries`);
        store.dispatch(StartGetCountriesSuccess(response));
    } catch (e) {
        dispatch({
            type: GET_COUNTRIES_FAILURE,
            error: 'Failed to load permissions'
        });
    }
};
export const LoadVerticals = (): ThunkAction<void, AppState, undefined, any> => async (dispatch, getState) => {
    dispatch({
        type: GET_VERTICALS_REQUEST
    });
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/verticals`);
        store.dispatch(StartGetVerticalsSuccess(response));
        return;
    } catch (e) {
        dispatch({
            type: GET_VERTICALS_FAILURE,
            error: 'Failed to load verticals'
        });
    }
};
