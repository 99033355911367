import { Dispatch, Action } from 'redux';

export type AdminActionTypes = 
    GetUsersRequest 
    | GetUsersFailure 
    | GetUsersSuccess
    | GetClientsRequest
    | GetClientsSuccess
    | GetClientsFailure
    | GetRolesRequest
    | GetRolesSuccess
    | GetRolesFailure
    | GetPermissionsRequest
    | GetPermissionsSuccess
    | GetPermissionsFailure;

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

interface GetUsersRequest extends Action<typeof GET_USERS_REQUEST> {}
interface GetUsersSuccess extends Action<typeof GET_USERS_SUCCESS> {
    payload: any[];
}
interface GetUsersFailure extends Action<typeof GET_USERS_FAILURE> {}

export const GetUsersRequest = (): GetUsersRequest => ({
    type: GET_USERS_REQUEST
});
export const GetUsersSuccess = (users: any[]): GetUsersSuccess => ({
    type: GET_USERS_SUCCESS,
    payload: users
});
export const StartGetUsersSuccess = (users: any[]) => {
    return (dispatch: Dispatch<GetUsersSuccess>) => {
        dispatch(GetUsersSuccess(users));
    };
};
export const GetUsersFailure = (): GetUsersFailure => ({
    type: GET_USERS_FAILURE
});

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAILURE = 'GET_CLIENTS_FAILURE';

interface GetClientsRequest extends Action<typeof GET_CLIENTS_REQUEST> {}
interface GetClientsSuccess extends Action<typeof GET_CLIENTS_SUCCESS> {
    payload: any[];
}
interface GetClientsFailure extends Action<typeof GET_CLIENTS_FAILURE> {}

export const GetClientsRequest = (): GetClientsRequest => ({
    type: GET_CLIENTS_REQUEST
});
export const GetClientsSuccess = (clients: any[]): GetClientsSuccess => ({
    type: GET_CLIENTS_SUCCESS,
    payload: clients
});
export const StartGetClientsSuccess = (clients: any[]) => {
    return (dispatch: Dispatch<GetClientsSuccess>) => {
        dispatch(GetClientsSuccess(clients));
    };
};
export const GetClientsFailure = (): GetClientsFailure => ({
    type: GET_CLIENTS_FAILURE
});

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

interface GetRolesRequest extends Action<typeof GET_ROLES_REQUEST> {}
interface GetRolesSuccess extends Action<typeof GET_ROLES_SUCCESS> {
    payload: any[];
}
interface GetRolesFailure extends Action<typeof GET_ROLES_FAILURE> {}

export const GetRolesRequest = (): GetRolesRequest => ({
    type: GET_ROLES_REQUEST
});
export const GetRolesSuccess = (roles: any[]): GetRolesSuccess => ({
    type: GET_ROLES_SUCCESS,
    payload: roles
});
export const StartGetRolesSuccess = (roles: any[]) => {
    return (dispatch: Dispatch<GetRolesSuccess>) => {
        dispatch(GetRolesSuccess(roles));
    };
};
export const GetRolesFailure = (): GetRolesFailure => ({
    type: GET_ROLES_FAILURE
});


export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';

interface GetPermissionsRequest extends Action<typeof GET_PERMISSIONS_REQUEST> {}
interface GetPermissionsSuccess extends Action<typeof GET_PERMISSIONS_SUCCESS> {
    payload: any[];
}
interface GetPermissionsFailure extends Action<typeof GET_PERMISSIONS_FAILURE> {}

export const GetPermissionsRequest = (): GetPermissionsRequest => ({
    type: GET_PERMISSIONS_REQUEST
});
export const GetPermissionsSuccess = (permissions: any[]): GetPermissionsSuccess => ({
    type: GET_PERMISSIONS_SUCCESS,
    payload: permissions
});
export const StartGetPermissionsSuccess = (permissions: any[]) => {
    return (dispatch: Dispatch<GetPermissionsSuccess>) => {
        dispatch(GetPermissionsSuccess(permissions));
    };
};
export const GetPermissionsFailure = (): GetPermissionsFailure => ({
    type: GET_PERMISSIONS_FAILURE
});
