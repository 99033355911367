import React, { useState } from 'react';
//Material-UI
import { Button, DialogTitle } from '@material-ui/core';
import { ButtonTooltip } from '../../../../../ButtonTooltip';
import AddIcon from '@material-ui/icons/Add';
//Components
import ManageRole from './ManageRole';

export default function CreateRole(props: any) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        
    };

    return (
        <div className='dialog'>
            <ButtonTooltip tooltipTitle={'Create new role'}
                isDisabled={props.disabled}
                titleDisabled={props.disabled ? 'Not enough permissions' : 'Create new role'}>
                <Button
                    id="basic-button"
                    disabled={props.disabled}
                    className="btn btn_highlight btn_highlight--invert"
                    variant="contained"
                    onClick={() => handleClickOpen()}
                >
                    <div className="add-role-title">
                        <AddIcon />
                        <span>Add Role</span>
                    </div>
                </Button>
            </ButtonTooltip>
            <ManageRole
                title={
                    <DialogTitle className="dialog-title">
                        <div className="add-client-title">
                            <AddIcon />
                            <h2>Add Role</h2>
                        </div>
                    </DialogTitle>
                }
                open={open}
                role={props.role}
                permissions={props.permissions}
                handleAgree={props.handleAgree}
                handleClose={handleClose}
            />
        </div>
    );
}
