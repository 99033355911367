import React from 'react';
//Material-UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

interface ILoaderProps {
    size?: number | string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            rigth: '0',
            bottom: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    })
);

export default function Loader() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CircularProgress />
        </div>
    );
}
