import { useCallback } from "react";
import { IUser } from "../interfaces";
import {
    ACCESS_CLIENT_MANAGEMENT_PERMISSIONS,
    ACCESS_ROLES_MANAGEMENT_PERMISSIONS,
    ACCESS_USER_MANAGEMENT_PERMISSIONS,
    READ_CLIENTS_PERMISSIONS,
    READ_USERS_PERMISSIONS
} from "../services/route-guards/config";

const usePermissionsCheck = () => {
    const hasPermissions = useCallback((permissions: string[], accountUser: IUser | undefined) => {
        return accountUser?.permissions
            .filter(p => permissions.includes(p.command))
            .length !== 0;
    }, []);

    const hasUserAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(READ_USERS_PERMISSIONS, accountUser)
            && hasPermissions(ACCESS_USER_MANAGEMENT_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasRolesAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(ACCESS_ROLES_MANAGEMENT_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasClientAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(READ_CLIENTS_PERMISSIONS, accountUser)
            && hasPermissions(ACCESS_CLIENT_MANAGEMENT_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const canAccessAdministration = useCallback((accountUser: IUser | undefined) => {
        return hasUserAccess(accountUser) || hasRolesAccess(accountUser) || hasClientAccess(accountUser);
    }, [hasUserAccess, hasRolesAccess, hasClientAccess]);

    return {
        hasPermissions,
        hasUserAccess,
        hasRolesAccess,
        hasClientAccess,
        canAccessAdministration
    };
};

export default usePermissionsCheck;