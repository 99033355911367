import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../Redux/store';
import { IRouter, IAccountState } from '../../interfaces';
import { useHistory } from 'react-router-dom';

interface IProps {
    routerState: IRouter;
    accountState: IAccountState;
    location: any;
}

const notAuthorizedPath = '/not-authorized';
export default function (ComposedComponent: any, ...permissions: any[]) {
    const AuthorizationGuard: React.FC<IProps> = ({ routerState, accountState, location }) => {
        const history = useHistory();

        useEffect(() => {
            const hasAccess = () => {
                if (permissions) {
                    let hasAccess = true;

                    for (let i = 0; i < permissions.length; i++) {
                        let rolePermission = accountState?.accountUser?.permissions.filter(
                            (perm: any) => permissions[i].includes(perm.command)
                        ).length;

                        hasAccess = hasAccess && (rolePermission ? true : false);
                    }

                    return hasAccess;
                }

                return false;
            };
            const isAuthorized = (): boolean => {
                return (
                    !!(accountState && accountState.accountUser && accountState.accountUser.isAuthorized) && hasAccess()
                );
            };

            const checkAuthorizationStatus = () => {
                if (!isAuthorized()) {
                    window.location.href = '/not-authorized';
                } else if (isAuthorized() && location?.pathname === notAuthorizedPath) {
                    history.push('/');
                }
            };
            checkAuthorizationStatus();
        }, [history, location, accountState]);

        return <ComposedComponent routes={routerState.routes} />;
    };
    const mapStateToProps = (state: AppState) => {
        return {
            routerState: state.routerReducer,
            accountState: state.accountReducer
        };
    };
    return connect(mapStateToProps)(AuthorizationGuard);
}
