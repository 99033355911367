import React from 'react';
import { Link } from 'react-router-dom';
import { State } from '../../../Redux/Reducers/accountReducer';
import { connect } from 'react-redux';
import { IPreAudit, IVertical } from '../../../interfaces';
import { formatForUrlParam } from '../../../utils/stringExtensions';

//Material-UI
import { Button } from '@material-ui/core';
import useStatusDisabled from './hooks/useStatusDisabled';
import useAudit from '../../../hooks/useAudit';

type OwnProps = {
    links: string[];
    currentCategory: string;
    preAuditId: number;
    currentVertical: IVertical;
};
type Props = OwnProps & LinkStateToProps;
const ButtonsNav: React.FC<Props> = (props) => {
    const { statusDisabled } = useStatusDisabled();

    const { auditType } = useAudit();

    let links = props.links.map((link) => formatForUrlParam(link));

    return (
        <div className="buttonsNav">
            {links.indexOf(props.currentCategory) <= 0 && (
                <Link to={`/`}>
                    <Button variant="contained" className="btn btn_outline">
                        Home
                    </Button>
                </Link>
            )}
            {links.indexOf(props.currentCategory) > 0 && (
                <Link to={`/audit/${auditType}/${props.preAuditId}/${links[links.indexOf(props.currentCategory) - 1]}`}>
                    <Button variant="contained" className="btn btn_outline">
                        Back
                    </Button>
                </Link>
            )}
            {links.indexOf(props.currentCategory) + 1 < links.length && (
                <Link
                    to={`/audit/${auditType}/${props.preAuditId}/${links[links.indexOf(props.currentCategory) + 1]}`}
                    onClick={(e) =>
                        statusDisabled(
                            links.indexOf(props.currentCategory) + 1,
                            props.actionCategoryId,
                            props.links,
                            props.currentVertical
                        )
                            ? e.preventDefault()
                            : null
                    }
                    className={
                        statusDisabled(
                            links.indexOf(props.currentCategory) + 1,
                            props.actionCategoryId,
                            props.links,
                            props.currentVertical
                        )
                            ? 'disabled'
                            : 'active'
                    }
                >
                    <Button
                        disabled={
                            statusDisabled(
                                links.indexOf(props.currentCategory) + 1,
                                props.actionCategoryId,
                                props.links,
                                props.currentVertical
                            )
                                ? true
                                : false
                        }
                        variant="contained"
                        className={`btn btn_highlight ${!statusDisabled(
                            links.indexOf(props.currentCategory) + 1,
                            props.actionCategoryId,
                            props.links,
                            props.currentVertical
                        ) && 'btn_highlight--attract'
                            }`}
                    >
                        Next
                    </Button>
                </Link>
            )}
            {links.indexOf(props.currentCategory) + 1 >= links.length && (
                <Link
                    to={`/audit/${auditType}/${props.preAuditId}/summary`}
                    onClick={(e) =>
                        statusDisabled(
                            links.indexOf(props.currentCategory) + 1,
                            props.actionCategoryId,
                            props.links,
                            props.currentVertical
                        )
                            ? e.preventDefault()
                            : null
                    }
                    className={
                        statusDisabled(
                            links.indexOf(props.currentCategory) + 1,
                            props.actionCategoryId,
                            props.links,
                            props.currentVertical
                        )
                            ? 'disabled'
                            : 'active'
                    }
                >
                    <Button
                        disabled={
                            statusDisabled(
                                links.indexOf(props.currentCategory) + 1,
                                props.actionCategoryId,
                                props.links,
                                props.currentVertical
                            )
                                ? true
                                : false
                        }
                        variant="contained"
                        className={`btn btn_highlight ${!statusDisabled(
                            links.indexOf(props.currentCategory) + 1,
                            props.actionCategoryId,
                            props.links,
                            props.currentVertical
                        ) && 'btn_highlight--attract'
                            }`}
                    >
                        Finish
                    </Button>
                </Link>
            )}
        </div>
    );
};

interface LinkStateToProps {
    actionCategoryId: number;
    preAudits: IPreAudit[];
}

const mapStateToProps = (state: State): LinkStateToProps => ({
    actionCategoryId: state.clientReducer.actionCategoryId,
    preAudits: state.clientReducer.preAudits
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(ButtonsNav);
