import { useCallback } from "react";
import { IScore, ISummary } from "../../../../interfaces";

const useSummary = () => {

    const getTotalScore = useCallback((summary: ISummary | undefined) => {
        if (summary) {
            const totalScore: number = +(
                (summary.scores.reduce((a: number, b: IScore) => a + b.score, 0) / summary.scores.length) *
                10
            ).toFixed(1);
            return totalScore;
        } return 0;
    }, []);

    return {
        getTotalScore
    };
};

export default useSummary;