export const READ_AUDITS_PERMISSIONS = [
    'ReadAudits'
];

export const CREATE_AUDITS_PERMISSIONS = [
    'CreateAudits'
];

export const UPDATE_AUDITS_PERMISSIONS = [
    'UpdateAudits'
];

export const READ_CLIENTS_PERMISSIONS: string[] = [
    'ReadClients'
];

export const CREATE_CLIENT_PERMISSIONS: string[] = [
    'CreateClients'
];

export const DELETE_CLIENT_PERMISSIONS: string[] = [
    'DeleteClients'
];

export const UPDATE_CLIENT_PERMISSIONS: string[] = [
    'UpdateClients'
];

export const READ_USERS_PERMISSIONS: string[] = [
    'ReadUsers'
];

export const CREATE_USERS_PERMISSIONS: string[] = [
    'CreateUsers'
];

export const DELETE_USERS_PERMISSIONS: string[] = [
    'DeleteUsers'
];

export const UPDATE_USERS_PERMISSIONS: string[] = [
    'UpdateUsers'
];

export const READ_ROLES_PERMISSIONS: string[] = [
    'ReadRoles'
];

export const CREATE_ROLES_PERMISSIONS: string[] = [
    'CreateRoles'
];

export const DELETE_ROLES_PERMISSIONS: string[] = [
    'DeleteRoles'
];

export const UPDATE_ROLES_PERMISSIONS: string[] = [
    'UpdateRoles'
];

export const ACCESS_CLIENT_MANAGEMENT_PERMISSIONS: string[] =
    [...CREATE_CLIENT_PERMISSIONS, ...DELETE_CLIENT_PERMISSIONS, ...UPDATE_CLIENT_PERMISSIONS];

export const ACCESS_USER_MANAGEMENT_PERMISSIONS: string[] =
    [...CREATE_USERS_PERMISSIONS, ...DELETE_USERS_PERMISSIONS, ...UPDATE_USERS_PERMISSIONS];

export const ACCESS_ROLES_MANAGEMENT_PERMISSIONS: string[] =
    [...READ_ROLES_PERMISSIONS, ...CREATE_ROLES_PERMISSIONS, ...DELETE_ROLES_PERMISSIONS, ...UPDATE_ROLES_PERMISSIONS];