import React from 'react';

interface ICategoryProps {
    category: string;
    recommendation: string;
    class: string;
}
export default function CategoryRecommendationText(props: ICategoryProps) {

    return (!props || !props.category || !props.recommendation)
        ? null
        : (<div className={props.class}>
            <div>
                <h3>{props.category}</h3>
                <div>{props.recommendation}</div>
            </div>
        </div>);
}
