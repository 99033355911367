import React, { useState, useEffect } from 'react';
import { getNotes, putNotes } from '../../../services/preAuditService';
//Material-UI
import { TextField } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';

interface iProps extends WithStyles<typeof styles> {
    children?: React.ReactNode;
    className?: string;
    preAuditId: number;
}
const styles = {
    root: {
        width: '90%',
        marginBottom: '1em',
        marginTop: '1em'
    }
};

function NotesField(props: iProps) {
    const { preAuditId } = props;
    const [notes, setNotes] = useState<string>('');

    useEffect(() => {
        let didCancel = false;

        (async function () {
            const savedNotes = await getNotes(preAuditId);
            if (!didCancel) {
                setNotes(savedNotes);
            }
        })();
        return () => {
            didCancel = true;
        };
    }, [preAuditId]);

    const handleNoteBlur = () => {
        putNotes(preAuditId, notes);
    };

    const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value);
    };

    return (
        <>
            <TextField
                classes={{ root: 'preaudit-input__container notes--input' }}
                id="standard-multiline-static"
                label="Notes"
                variant="filled"
                multiline
                minRows={4}
                onChange={handleNoteChange}
                onBlur={handleNoteBlur}
                value={notes}
            />
        </>
    );
}

export default withStyles(styles)(NotesField);
