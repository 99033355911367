import React from 'react';
import { connect } from 'react-redux';
import { IRouter, IAccountState } from '../../interfaces';
import Loader from '../../components/Loader/Loader';
import { AppState } from '../../Redux/store';

interface IProps {
    routerState: IRouter;
    accountState: IAccountState;
}

export default function (ComposedComponent: any) {
    const Authenticate: React.FC<IProps> = ({ accountState, routerState }) => {
        const isAuthenticated = (): any => {
            return !!(accountState && accountState.accountUser && accountState.accountUser.email);
        };
        return isAuthenticated() ? <ComposedComponent routes={routerState.routes} /> : <Loader />;
    };

    const mapStateToProps = (state: AppState) => {
        return {
            routerState: state.routerReducer,
            accountState: state.accountReducer
        };
    };

    return connect(mapStateToProps)(Authenticate);
}
