import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { formatForUrlParam } from '../../../utils/stringExtensions';

import { IVertical, IPreAudit, IVerticalCategory, IQuestion } from '../../../interfaces';
import { AppState, store } from '../../../Redux/store';
import { SetCategorySuccess } from '../../../Redux/Actions/client';
import { LoadQuestions } from '../../../services/userService';
import { getActionCategory, getPreAuditStatus } from '../../../services/preAuditService';
import { LoadCurrentPreAudit } from '../../../services/fetchPreAudit';

//Components
import { Loader } from '../../Loader';
import QuestionsTable from './QuestionsTable';
import SubNav from './SubNav';
import useAudit from '../../../hooks/useAudit';

interface ParamTypes {
    preAuditId: string;
    category: string;
}

type Props = LinkStateToProps | any;
const PreAuditForm: React.FC<Props> = (props) => {
    const [showChildComponents, setshowChildComponents] = useState(false);
    const { verticals } = props;
    const { preAuditId, category } = useParams<ParamTypes>();
    const [currentPreAudit, setCurrentPreAudit] = useState<IPreAudit>({} as IPreAudit);
    const [currentVertical, setCurrentVertical] = useState<IVertical>({} as IVertical);
    const [currentCategories, setCurrentCategories] = useState<string[]>([]);
    const [questions, setQuestions] = useState<IQuestion[] | any>([]);
    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    const { auditType } = useAudit();

    useEffect(() => {
        let isFetching = true;
        (async function () {
            if (isFetching) {
                const auditStatus: any = await getPreAuditStatus(+preAuditId);
                if (auditStatus === 2) {
                    history.push(`/audit/${auditType}/${preAuditId}/report`);
                } else {
                    const preAudit = await LoadCurrentPreAudit(+preAuditId);
                    setCurrentPreAudit(preAudit);
                }
            }
        })();
        return () => {
            isFetching = false;
        };
    }, [history, preAuditId, auditType]);

    useEffect(() => {
        if (currentPreAudit && currentPreAudit.id) {
            setCurrentVertical(
                verticals.filter((vertical: IVertical) => {
                    return vertical.id === currentPreAudit.vertical.id;
                })[0]
            );
            getActionCategory(currentPreAudit.id);
        }
    }, [currentPreAudit, currentVertical, verticals, props.actionCategoryId, props.currentCategoryId, preAuditId]);

    useEffect(() => {
        let isFetching = true;
        if (currentVertical && currentVertical.id) {
            const categoriesOfAuditType = currentVertical.categories
                .filter((category: IVerticalCategory) => category.auditType.id === currentPreAudit.auditType.id);

            setCurrentCategories(categoriesOfAuditType.map((category: IVerticalCategory) => category.name));

            let currentCategoryId: number = categoriesOfAuditType
                .filter((x: IVerticalCategory) => formatForUrlParam(x.name) === formatForUrlParam(category))[0].id;

            store.dispatch(SetCategorySuccess(currentCategoryId));
            (async () => {
                let questionsApi = await store.dispatch(LoadQuestions(+preAuditId, currentCategoryId));
                if (isFetching) {
                    setQuestions(questionsApi);
                    setIsLoading(false);
                }
            })();
        }
        return () => {
            isFetching = false;
        };
    }, [category, currentVertical, preAuditId, currentPreAudit]);

    useEffect(() => {
        if (currentPreAudit && currentVertical && currentCategories && questions) {
            setshowChildComponents(true);
        }
    }, [currentPreAudit, currentVertical, currentCategories, questions]);

    return (
        <>
            {isLoading ? (
                <div>
                    <Loader />
                </div>
            ) : (
                showChildComponents && (
                    <>
                        <SubNav
                            preAuditId={preAuditId}
                            categories={currentCategories}
                            currentVertical={currentVertical} />
                        <QuestionsTable
                            questions={questions}
                            preAuditId={parseInt(preAuditId, 10)}
                            currentPreAudit={currentPreAudit}
                            currentVertical={currentVertical}
                            currentCategories={currentCategories}
                            currentCategory={category}
                        />
                    </>
                )
            )}
        </>
    );
};

interface LinkStateToProps {
    selectedVertical: IVertical;
    preAuditId: number;
    verticals: IVertical[];
    preAudits: IPreAudit[];
    actionCategoryId: number;
    currentCategoryId: number;
}

const mapStateToProps = (state: AppState): LinkStateToProps => ({
    selectedVertical: state.clientReducer.selectedVertical,
    preAuditId: state.clientReducer.preAuditId,
    verticals: state.clientReducer.verticals,
    preAudits: state.clientReducer.preAudits,
    actionCategoryId: state.clientReducer.actionCategoryId,
    currentCategoryId: state.clientReducer.currentCategoryId
});

export default withRouter(connect(mapStateToProps)(PreAuditForm));
