import { IUser } from '../interfaces/user';
import * as http from './http';
import { ApiBaseUrl } from '../config';

const currentLocation = window.location.origin;

// GET Identity
export function getIdentity(): Promise<IUser> {
    return http.GET(`${ApiBaseUrl}/account/identity`);
}

// Login
export function login() {
    window.location.href = `${ApiBaseUrl}/account/login?returnUrl=${currentLocation}`;
}

export async function logout() {
    window.location.href = `${ApiBaseUrl}/account/logout?returnUrl=${currentLocation}`;
}
