import React, { useEffect } from 'react';
//Material-UI
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';

export default function WarningComponent(props: any) {
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        setOpen(props.showWarning);
    }, [props.showWarning]);
    const handleClose = () => {
        props.setShowWarning(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Please save your changes first!'}</DialogTitle>
                <DialogActions>
                    <Button className="btn btn_basic" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
