import React, { FC } from 'react';
import { connect } from 'react-redux';
import { LoadRoles, postRole } from '../../../../../services/roleService';
import { State } from '../../../../../Redux/Reducers/routerReducer';
import { store } from '../../../../../Redux/store';
import { IPermission, IRole, IUser } from '../../../../../interfaces';
import { Grid } from '@material-ui/core';
import RoleCard from './components/RoleCard';
import CreateRole from './components/CreateRole';
import useAdminPermissionsCheck from '../../hooks/usePermissionsCheck';

interface OwnProps {}
type Props = OwnProps & LinkStateToProps;
const RolesManagement: FC<Props> = ({ roles, permissions, accountUser }) => {
    const newRole = {
        name: '' as string,
        description: '' as string,
        id: 0 as number,
        permissions: [] as IPermission[]
    };

    const { hasCreateRolesAccess, hasUpdateRolesAccess, hasDeleteRolesAccess } = useAdminPermissionsCheck();

    const handleChange = async (role: IRole, action: string) => {
        switch (action) {
            case 'create':
                await postRole(role);
                break;
        }
        store.dispatch(LoadRoles());
    };

    return (
        <div className="roles--table__container">
            <div className="roles--table__header">
                <h1>Roles</h1>
                {hasCreateRolesAccess(accountUser) ? (
                    <CreateRole
                        role={newRole}
                        permissions={permissions}
                        handleAgree={(r: IRole) => handleChange(r, 'create')}
                    />
                ) : (
                    <CreateRole role={newRole} permissions={permissions} handleAgree={() => {}} disabled={true} />
                )}
            </div>

            <Grid container spacing={4} alignContent="flex-start">
                {roles.map((role) => {
                    return (
                        <Grid className="grid-item" key={role.id} item xs={12} md={6} lg={4}>
                            <RoleCard
                                role={role}
                                permissions={permissions}
                                accountUser={accountUser}
                                canEdit={hasUpdateRolesAccess(accountUser)}
                                canDelete={hasDeleteRolesAccess(accountUser)}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

interface LinkStateToProps {
    roles: IRole[];
    permissions: IPermission[];
    accountUser: IUser;
}
const mapStateToProps = (state: any): LinkStateToProps => ({
    roles: state.adminReducer.roles,
    permissions: state.adminReducer.permissions,
    accountUser: state.accountReducer.accountUser
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(RolesManagement);
