import React, { FC, useEffect, useState } from 'react';
import halcomRegular from './../../../../../src/assets/fonts/halcom/HalcomRegular/font.ttf';
import halcomBold from './../../../../../src/assets/fonts/halcom/HalcomBold/font.ttf';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Image as PDFImage } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet, pdf, Font } from '@react-pdf/renderer';
import { IPreAudit, ISummary, ICategoryRecommendation } from '../../../../interfaces';
import { State } from '../../../../Redux/Reducers/accountReducer';
import { store } from '../../../../Redux/store'
import { completeAudit } from '../../../../services/preAuditService';
//Components
import { Loader } from '../../../Loader';
//Material-UI
import { Button } from '@material-ui/core';
import { getSummaryReportTitle } from '../../../../utils/summaryExtensions';
import { LoadQuestions } from '../../../../services/userService';
import PDFquestionSummaryTable from './PDFquestionSummaryTable';
let dentsuLogoImage = require('../../../../assets/images/dentsu logo_white.png');

interface IProps {
    summary: ISummary;
    chartImg: string;
    consultant: string;
    email: string;
    handleSetComplete: any;
    completed: boolean;
    totalScore: number;
}

interface ParamTypes {
    preAuditId: string;
}

interface ITextBox {
    title: string;
    text: string;
}

interface OwnProps { }
type Props = OwnProps & IProps & LinkStateToProps;

const PDFdoc: FC<Props> = ({ summary, totalScore, chartImg, email, consultant, handleSetComplete, completed }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { preAuditId } = useParams<ParamTypes>();
    const [renderComponent, setRenderComponent] = useState<boolean>(true);

    // a 'dictionary' which holds a category ID in its keys, and array of IQuestions in its values
    const [questionsByCategory, setQuestionsByCategory] = useState<{ [categoryId: number]: any; }>({});

    // questions in the above dictionary get loaded asynchrously from the backend.
    // this value will be set to 'true' once all of the relevant questions are loaded.
    const [areQuestionsLoaded, setAreQuestionsLoaded] = useState<boolean>(false);

    var utc = new Date().toJSON().slice(0, 10);
    const filename = `dentsu-Audit_${summary.client.name}_${summary.client.id}.pdf`;

    // Register font
    Font.register({
        family: 'Halcom-Regular',
        src: halcomRegular
    });
    Font.register({
        family: 'Halcom-Bold',
        src: halcomBold
    });
    const styles = StyleSheet.create({
        page: {
            backgroundColor: 'white',
            color: 'black',
            fontFamily: 'Halcom-Regular'
        },
        head: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: 'white',
            backgroundColor: '#05051e',
            padding: 25,
            fontSize: 9,
            lineHeight: 1.3,
            fontFamily: 'Halcom-Bold'
        },
        section: {
            fontSize: 10,
            borderRadius: 2,
            lineHeight: 1.3,
            marginBottom: 12
        },
        image: {
            maxWidth: '150px',
            maxHeight: '70px'
        },
        chartWrapper: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 20
        },
        chartHeader: {
            padding: 25
        },
        chartTitle: {
            fontSize: 12,
            fontFamily: 'Halcom-Bold',
            marginBottom: 5
        },
        chartSubtitle: {
            fontSize: 10,
            marginBottom: 10
        },
        textsContainer: {
            height: '840px',
            width: '100%',
            flex: 2,
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            alignContent: 'flex-start'
        },
        textsContainerFirstPage: {
            height: '350px',
            width: '100%',
            flex: 2,
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            alignContent: 'flex-start'
        },
        text: {
            width: '50%',
            paddingLeft: 25,
            paddingRight: 25
        },
        title: {
            marginTop: 20,
            fontSize: 12,
            fontFamily: 'Halcom-Bold',
            marginBottom: 10
        },
        invisible: {
            display: 'none'
        }
    });

    const replaceNewLines = (input: string): string => { return input?.replaceAll('\r\n', '\n'); }

    // Load questions from the backend
    useEffect(() => {
        (async function () {
            for (const category of summary.scores) {
                const questions = await store.dispatch(LoadQuestions(+preAuditId, category.id));
                setQuestionsByCategory(questionsByCategory => ({
                    ...questionsByCategory, [category.id]: questions
                })
                );
            };
        })();

    }, [summary, preAuditId]);

    useEffect(() => {
        const numberOfLoadedCategories = Object.keys(questionsByCategory as any).length;
        if (numberOfLoadedCategories < summary.scores.length)
            return;

        setAreQuestionsLoaded(true);
    }, [questionsByCategory, summary]);
    
    const renderTextBoxes = () => {
        if (!summary)
            return null;

        let textBoxes:ITextBox[] = [];
        if (summary.summary && summary.summary.length > 0)
            textBoxes.push({ title: 'Summary', text: summary.summary });

        if (summary.recommendations && summary.recommendations.length > 0)
            textBoxes.push({ title: 'Recommendations', text: summary.recommendations });

        if (summary.categoryRecommendations) 
            summary.categoryRecommendations.forEach((cr:ICategoryRecommendation) => {
                if (cr.recommendation && cr.recommendation.length > 0)
                    textBoxes.push({title: cr.categoryName, text: cr.recommendation});
            });

        if (summary.fullAudit && summary.fullAudit.length > 0)
            textBoxes.push({ title: 'Full-Audit', text: summary.fullAudit });

        if (summary.followUp && summary.followUp.length > 0)
            textBoxes.push({ title: 'Follow-Up', text: summary.followUp });

        /* Where do these numbers come from? I trial-an-error'd varius values until it looked good.
        yes probably not the best way of going about it, but it's the best we've got for now */
        const maximumLines = 90;
        const maximumLinesFirstPage = 50;
        const maximumCharsPerLine = 50;

        const groupedTextBoxes = textBoxes.reduce<ITextBox[][]>( (allGroups:ITextBox[][], i_textBox:ITextBox) => {
            const lastGroup = allGroups[allGroups.length - 1];
            const lineCount = lastGroup.reduce((lines, tb) => { 
                lines += 6;// let's say that title (+margins) is worth 6 lines
                let characters = 0;
                for (var i = 0; i < tb.text.length; i++) {
                    if (characters > maximumCharsPerLine || tb.text.charAt(i) === '\r\n' || tb.text.charAt(i) === '\n')
                    {
                        lines++;
                        characters = 0;
                    }
                    else
                    {
                        //m, w and uppercase characters are chunky, we count them as 1.5
                        if (tb.text.charAt(i) === 'm' || tb.text.charAt(i) === 'w' || (tb.text.charAt(i) === tb.text.charAt(i).toUpperCase()))
                            characters += 1.5;
                        // i and l are extra skinny, count them as 0.5
                        else if (tb.text.charAt(i) === ' ' || tb.text.charAt(i) === 'i' || tb.text.charAt(i) === 'l')
                            characters += 0.5;
                        else
                            characters += 1;
                    }
                }
                return lines;
            }, 0);

            let maximumLineCount = (allGroups.length === 1 ? maximumLinesFirstPage : maximumLines);
            if (lineCount <= maximumLineCount)
            {
                lastGroup.push(i_textBox);
            }
            else
            {
                const newGroup:ITextBox[] = [i_textBox];
                allGroups.push(newGroup)
            }
            return allGroups;
        }, [ [] ]);

        return (
            <View>{
                groupedTextBoxes.map((textBoxGroup, i) => {
                    return (
                        <View style={i > 0 ? styles.textsContainer : styles.textsContainerFirstPage} key={i} break={i > 0}>
                            {textBoxGroup.map((textBox) => {
                                return (
                                    <View style={styles.text} key={textBox.text}>
                                        <Text style={styles.title}>{textBox.title}</Text>
                                        <Text style={styles.section}>{replaceNewLines(textBox.text)}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    )
                })
            }</View>
        );
    }

    const MyDoc = () => {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.head}>
                        <View style={styles.image}>
                            <PDFImage src={dentsuLogoImage} />
                        </View>
                        <View>
                            <Text>Client:&nbsp;{summary.client.name.charAt(0).toUpperCase() + summary.client.name.slice(1)}</Text>
                            <Text>Date:&nbsp;{utc}</Text>
                            <Text>Vertical:&nbsp;{summary.vertical.name}</Text>
                            <Text>Consultant:&nbsp;{consultant}</Text>
                            <Text>Contact:&nbsp;{email}</Text>
                        </View>
                    </View>
                    <View style={styles.chartHeader}>
                        <Text style={styles.chartTitle}>
                            {getSummaryReportTitle(summary)} - &nbsp;
                            {summary.client.name.charAt(0).toUpperCase() + summary.client.name.slice(1)}
                        </Text>
                        <Text style={styles.chartSubtitle}>Total score: &nbsp;{totalScore}%</Text>
                    </View>
                    <View style={styles.chartWrapper}>
                        <PDFImage src={chartImg} />
                    </View>

                    {renderTextBoxes()}
                </Page>
                <PDFquestionSummaryTable categories={summary.scores} questionsByCategory={questionsByCategory} />
            </Document>
        );
    };

    const uploadFileRequest = (file: any, filename: string) => {
        const data = new FormData();
        data.append('report', file, filename);
        data.append('id', preAuditId);
        (async function () {
            const completed: any = await completeAudit(data);
            if (completed) {
                handleSetComplete();
                setLoading(false);
                setRenderComponent(false);
            }
        })();
    };

    async function handleComplete() {
        const doc = <MyDoc />;
        const asPdf = pdf(MyDoc());
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        uploadFileRequest(blob, filename);
    }

    //ui
    const completeButton = (
        <Button
            className="btn btn_highlight btn-complete"
            variant="contained"
            onClick={() => {
                handleComplete();
                setLoading(true);
            }}
            disabled={summary.status !== 1 || loading || !areQuestionsLoaded}
        >
            Complete Audit
        </Button>
    );
    const loader = loading && <Loader />;


    /* Only used for debugging. This helps you regenerate-and-save the PDF locally without contacting the BE */
    const saveToLocalRequest = async () => {
        const doc = <MyDoc />;
        const asPdf = pdf(MyDoc());
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();

        var a = document.createElement("a"),
        url = URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    };
    /* set this to 'true' when in debug mode */
    const debugDownloadButton = false && (<Button className="btn" onClick={() => {saveToLocalRequest()}}>DEBUG: download PDF locally</Button>);

    return (
        <>
            {renderComponent && (
                <>
                    {completeButton}
                    {debugDownloadButton}
                </>
            )}
        </>
    );
};

interface LinkStateToProps {
    preAudits: IPreAudit[];
}
const mapStateToProps = (state: State): LinkStateToProps => ({
    preAudits: state.clientReducer.preAudits
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(PDFdoc);
