import React from 'react';
import { Switch, Route } from 'react-router-dom';
// Material UI
import Container from '@material-ui/core/Container';
import NotFound from '../components/NotFound/NotFound';
import { IRoute } from '../interfaces';

// render route with sub routes
export const RootRouteComponentWithSubRoutes = ({ routes }: { routes: IRoute[] }) => {
    return (
        <Switch>
            {routes.map((route) => (
                <RouteWithSubRoutes {...route} />
            ))}
            <Route component={NotFound} />
        </Switch>
    );
};

// render App Routes with Sub Routes
export const RootAppComponentWithRoutes = ({ routes }: { routes: IRoute[] }) => {
    return (
        <Container classes={{ root: 'container--main' }} maxWidth={false}>
            <RootRouteComponentWithSubRoutes routes={routes} />
        </Container>
    );
};

// allows for rendering nested Route components
export const RouteWithSubRoutes = (route: IRoute) => {
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props) => <route.component {...props} routes={route.routes} />}
        />
    );
};
