import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import ManageClient from './ManageClient';
import AddIcon from '@material-ui/icons/Add';
import { ButtonTooltip } from '../../../../../ButtonTooltip';

export default function CreateClient(props: any) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    return (
        <div className="dialog">
            <ButtonTooltip
                tooltipTitle={'Create new client'}
                isDisabled={props.disabled}
                titleDisabled={props.disabled ? 'Not enough permissions' : 'Create new client'}
            >
                <Button
                    id="basic-button"
                    className="btn btn_highlight btn_highlight--invert"
                    variant="contained"
                    onClick={(e) => handleClickOpen()}
                    disabled={props.disabled ? true : false}
                >
                    <div className="add-client-title">
                        <AddIcon />
                        <span>Add Client</span>
                    </div>
                </Button>
            </ButtonTooltip>
            <ManageClient
                title={
                    <div className="add-client-title">
                        <AddIcon />
                        <h2>Add Client</h2>
                    </div>
                }
                open={open}
                client={props.client}
                handleAgree={props.handleAgree}
                handleClose={handleClose}
            />
        </div>
    );
}
