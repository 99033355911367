import React from 'react';
import { IRole } from '../../../../../../interfaces';
import { Chip } from '@material-ui/core';

export default function RolesView(props: any) {
    return (
        <div>
            {props.roles.map((role: IRole, index: number) => {
                return (
                    <Chip key={index} label={role.name} className='user--table__body__cell__chip' />
                );
            })}
        </div>
    );
};