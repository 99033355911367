import React, { useState } from 'react';
//Material-UI
import { IconButton, DialogTitle } from '@material-ui/core';
import { ButtonTooltip } from '../../../../../ButtonTooltip';
import EditIcon from '@material-ui/icons/Edit';
//Components
import ManageRole from './ManageRole';

export default function UpdateRole(props: any) {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
        
    };

    return (
        <div>
            <ButtonTooltip
                tooltipTitle={'Update role'}
                isDisabled={props.disabled}
                titleDisabled={props.disabled ? 'Not enough permissions' : 'Update role'}
            >
                <IconButton disabled={props.disabled} color="secondary" onClick={() => handleClickOpen()}>
                    <EditIcon className="user--table__body__cell__edit" />
                </IconButton>
            </ButtonTooltip>
            <ManageRole
                title={
                    <DialogTitle className="dialog-title">
                        <div className="edit-client-title">
                            <h2><EditIcon />Edit Role</h2>
                        </div>
                    </DialogTitle>
                }
                open={open}
                role={props.role}
                permissions={props.permissions}
                handleAgree={props.handleAgree}
                handleClose={handleClose}
            />
        </div>
    );
}
