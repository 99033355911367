import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from '../../../Redux/store';
import { IPreAudit, IQuestion, IVertical, IAnswerOption, IAnswer } from '../../../interfaces';
import { postAnswer } from '../../../services/preAuditService';
import { GetCategoryActionSuccess } from '../../../Redux/Actions/client';
import { State } from '../../../Redux/Reducers/accountReducer';
import ProgressBar from './PregressBar';
import ClientInfo from './ClientInfo';
import ButtonsNav from './ButtonsNav';
//Material-UI
import {
    Input,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    RadioGroup,
    FormControlLabel
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';


interface OwnProps {
    questions: IQuestion[];
    preAuditId: number;
    selectedVertical?: IVertical;
    currentPreAudit: IPreAudit;
    currentVertical: IVertical;
    currentCategories: string[];
    currentCategory: string;
}

type Props = OwnProps & LinkStateToProps;
const QuestionsTable = (props: Props) => {
    const { questions, currentPreAudit, currentCategories, currentCategory, currentVertical } = props;
    const [answers, setAnswers] = useState<IAnswer[]>([]);
    const [answersCounter, setAnswersCounter] = useState<number>(0);
    const [filledQuestions] = useState<number[]>([]);
    useEffect(() => {
        if (!!questions.length) {
            let initialAnswers:IAnswer[] = questions.map((question) => {
                return {
                    preAuditId: currentPreAudit.id,
                    questionId: question.id,
                    score: question.answer ? question.answer.score : 0,
                    answerOptionId: question.answer ? question.answer.answerOptionId : null
                };
            });
            setAnswers(initialAnswers);
        }
    }, [currentPreAudit.id, questions]);
    useEffect(() => {
        if (questions.length && answers.length) {
            let questionsCounter = questions.length;
            let answersCounter = answers.reduce((acc: number, curr: IAnswer) => {
                if (curr.answerOptionId !== null) {
                    acc++;
                }
                return acc;
            }, 0);
            setAnswersCounter(answersCounter);

            if (answersCounter >= questionsCounter) {
                if (props.currentCategoryId >= props.actionCategoryId) {
                    store.dispatch(GetCategoryActionSuccess(props.currentCategoryId + 1));
                }
            }
        }
    }, [questions, answers, props.currentCategoryId, props.actionCategoryId]);

    function handleRadioChange(e: React.ChangeEvent<HTMLInputElement>, questionId: number, maxScore: number) {
        const newAnswers = answers.map((savedAnswer: IAnswer) => {
            if (savedAnswer.questionId === questionId) {
                let updatedScore;
                //answer is yes
                if (e.target.value === '1') {
                    updatedScore = maxScore;
                } else {
                    updatedScore = 0;
                }
                let updatedAnswer = {
                    ...savedAnswer,
                    answerOptionId: parseInt(e.target.value, 10),
                    score: updatedScore
                };
                postAnswer(updatedAnswer);
                return updatedAnswer;
            } else {
                filledQuestions.push(questionId);
                return savedAnswer;
            }
        });
        setAnswers(newAnswers);
    }

    function handleScoreChange(e: React.ChangeEvent<HTMLInputElement>, questionId: number) {
        let newScore = Number(e.target.value);
        setScoreChange(newScore, questionId, false);
    }

    function handleScoreBlur(e: React.FocusEvent<HTMLInputElement>, quetionId: number, maxScore: number) {
        let newScore;
        if (+e.target.value < 0 || e.target.value === '') {
            newScore = 0;
        } else if (+e.target.value > 10) {
            newScore = maxScore;
        } else {
            newScore = Number(e.target.value);
        }
        setScoreChange(newScore, quetionId, true);
    }

    function setScoreChange(newScore: number, questionId: number, backendUpdate: boolean) {
        const newAnswers = answers.map((savedAnswer: IAnswer) => {
            if (savedAnswer.questionId === questionId) {
                let updatedAnswer = { ...savedAnswer, score: newScore };
                if (backendUpdate) {
                    postAnswer(updatedAnswer);
                }
                return updatedAnswer;
            } else {
                return savedAnswer;
            }
        });
        setAnswers(newAnswers);
    }
    return (
        <>
            {questions.length && answers.length && (
                <div className="question-page">
                    <div className="question-table__section">
                        <TableContainer component={Paper} className="table question-table__container">
                            <Table className="question-table" aria-label="simple table" stickyHeader>
                                <TableHead className="question-table__header">
                                    <TableRow className="question-table__header__row">
                                        <TableCell className="question-table__header__cell" align="left">{currentPreAudit.auditType.id === 2 ? "Maturity Level" : "Topic"}</TableCell>
                                        <TableCell className="question-table__header__cell">Question</TableCell>
                                        <TableCell className="question-table__header__cell available--option" align="left" >
                                            Available
                                        </TableCell>
                                        <TableCell className="question-table__header__cell score--option" align="left" >
                                            Score
                                        </TableCell>
                                        <TableCell className="question-table__header__cell" align="left">Max Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="question-table__body">
                                    {questions &&
                                        questions.map((question: IQuestion, qindex: number) => {
                                            return (
                                                <TableRow key={question.id} classes={{
                                                    root: `question-table__body__row hover-grey ${(question.answer?.id ||
                                                        filledQuestions.indexOf(question.id) >= 0)
                                                        && 'has-answer'}`
                                                }}>
                                                    <TableCell className='question-table__body__cell'>
                                                        <p>
                                                            {question.topic.name.charAt(0).toUpperCase() +
                                                                question.topic.name.slice(1)}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell className='question-table__body__cell'>
                                                        <p>
                                                            {question.text}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell className='question-table__body__cell available--option'>
                                                        <RadioGroup
                                                            onChange={(e) =>
                                                                handleRadioChange(e, question.id, question.maxScore)
                                                            }
                                                            className="question-table__body__cell radio-buttons"
                                                        >
                                                            {question.answerOptions &&
                                                                answers.length &&
                                                                question.answerOptions.map((answerOption: IAnswerOption) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            key={answerOption.id}
                                                                            classes={{ root: 'radio-button__option', label: 'radio-button__label' }}
                                                                            value={answerOption.id}
                                                                            label={answerOption.name}
                                                                            control={
                                                                                <Radio
                                                                                    classes={{ root: 'radio-button', checked: 'radio-button--checked' }}
                                                                                    checked={
                                                                                        answers[qindex].answerOptionId ===
                                                                                        answerOption.id
                                                                                    }
                                                                                />}
                                                                        />
                                                                    );
                                                                })}
                                                        </RadioGroup>
                                                    </TableCell>
                                                    <TableCell classes={{
                                                        root:
                                                            `question-table__body__cell score--option ${question.answer?.id ||
                                                                filledQuestions.indexOf(question.id) >= 0
                                                                ? 'has-answer'
                                                                : ''}`
                                                    }}>
                                                        <Input
                                                            className='score-input'
                                                            value={answers[qindex].score}
                                                            disabled={answers[qindex].answerOptionId !== 4}
                                                            margin="dense"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleScoreChange(e, question.id)}
                                                            inputProps={{
                                                                step: 1,
                                                                min: 0,
                                                                max: question.maxScore,
                                                                type: 'number',
                                                                'aria-labelledby': 'input-slider'
                                                            }}
                                                            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                                                                handleScoreBlur(
                                                                    e,
                                                                    question.id,
                                                                    question.maxScore
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell className="question-table__body__cell">
                                                        <p>
                                                            {answers[qindex].answerOptionId === 3 ? '0' : question.maxScore}
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="question-table--infobox">
                        <ClientInfo currentPreAudit={props.currentPreAudit} currentVertical={props.currentVertical} />
                        <ProgressBar questions={questions.length} answers={answersCounter} />
                        <ButtonsNav
                            preAuditId={currentPreAudit.id!}
                            links={currentCategories}
                            currentCategory={currentCategory}
                            currentVertical={currentVertical}
                        />
                    </div>

                </div>
            )
            }
        </>
    );
};

interface LinkStateToProps {
    currentCategoryId: number;
    actionCategoryId: number;
}

const mapStateToProps = (state: State): LinkStateToProps => ({
    currentCategoryId: state.clientReducer.currentCategoryId,
    actionCategoryId: state.clientReducer.actionCategoryId
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(QuestionsTable);
