import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import * as Yup from 'yup';

const CLIENT_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Client name is required')
});

export default function ManageClient(props: any) {
    const [client, setClient] = useState(props.client);
    const [formValues, setFormValues] = useState({
        name: client.name,
        description: client.description
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errors, setErrors] = useState([] as string[]);

    const handleConfirm = () => {
        (async function validate() {
            const errors = await CLIENT_SCHEMA.validate({ ...formValues }, { abortEarly: false }).catch(function (err) {
                return err;
            });

            if (errors?.errors?.length) {
                setErrors(errors.errors);
                setFormSubmitted(true);
                return;
            } else {
                props.handleClose();
                props.handleAgree(client);
                setClient(props.client);
            }
        })();
    };

    const handleValueChange = (name: string, value: string) => {
        setClient({
            ...client,
            [name]: value
        });

        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const errorFound = (errors: string[], name: string) => {
        return errors.filter((error: string) => error.includes(name));
    };

    const validateInputs = () => {
        if (formSubmitted) {
            (async function validate() {
                const errors = await CLIENT_SCHEMA.validate({ ...formValues }, { abortEarly: false }).catch(function (
                    err
                ) {
                    return err;
                });
                if (errors?.errors?.length) {
                    setErrors(errors.errors);
                } else if (errors?.errors === undefined) {
                    setErrors([]);
                }
            })();
        }
    };

    useEffect(() => {
        setClient(props.client);
    }, [props.client]);

    return (
        <Dialog
            className="dialog"
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <div className="dialog-clients">
                {props.title}

                <h4>Client Information</h4>

                <DialogContent className="content">
                    <TextField
                        classes={{ root: 'preaudit-input__container' }}
                        id="client-name"
                        label="Client Name"
                        name="client"
                        variant="filled"
                        value={client.name}
                        onChange={(e) => handleValueChange('name', e.target.value)}
                        autoComplete="off"
                        error={errorFound(errors, 'name')[0]?.length ? true : false}
                        helperText={errorFound(errors, 'name')[0]?.length ? errorFound(errors, 'name')[0] : null}
                        className={errorFound(errors, 'name')[0]?.length ? 'alert-border' : ''}
                        onBlur={validateInputs}
                    />
                </DialogContent>
                <DialogContent className="content">
                    <TextField
                        classes={{ root: 'preaudit-input__container' }}
                        id="client-description"
                        label="Client Description"
                        name="description"
                        variant="filled"
                        value={client.description}
                        onChange={(e) => handleValueChange('description', e.target.value)}
                    />
                </DialogContent>
                <DialogActions className="actions">
                    <Button className="btn btn_basic" onClick={props.handleClose}>
                        Cancel
                    </Button>
                    <Button className="btn btn_basic" onClick={(e) => handleConfirm()} autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}
