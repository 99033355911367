export interface IAuditType {
    name: string;
    id: number;
    description: string;
}

export enum AuditType {
    CROPreAudit = 1,
    CROMaturity = 2,
    iActivate = 3
}
