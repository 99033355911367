import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        secondary: {
            main: '#5B19C4'
        }
    }
});
const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '15px',
        borderRadius: '10px',
        backgroundColor: '#E5E5E9',
        marginTop: '3rem',
        marginBottom: '2rem'
    }
});

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number } & { max: number } & { answerscounter: number }
) {
    const classes = useStyles();
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <MuiThemeProvider theme={theme}>
                    <LinearProgress variant="determinate" {...props} color="secondary" className={classes.root} />
                </MuiThemeProvider>
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${props.answerscounter} / ${props.max}`}
                </Typography>
            </Box>
        </Box>
    );
}

interface Props {
    questions: number;
    answers: number;
}
const ProgressBar: React.FC<Props> = (props) => {
    const precentage = (1 / props.questions) * 100;
    const [progress, setProgress] = useState(props.answers * precentage);
    const [answers, setAnswers] = useState(props.answers);

    useEffect(() => {
        if (props.answers !== answers) {
            setAnswers(props.answers);
            setProgress(props.answers * precentage);
        }
    }, [props.answers, answers, props.questions, precentage]);

    return (
        <div className="progress-bar">
            <LinearProgressWithLabel value={progress} max={props.questions} answerscounter={props.answers} />
        </div>
    );
};
export default ProgressBar;
