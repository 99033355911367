import React from 'react';
import { connect } from 'react-redux';

//Material-UI
import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

//PreAudit
import { AppState } from '../../../../Redux/store';
import { ICountry } from '../../../../interfaces';

type Props = LinkStateToProps | any;
const MultipleSelect: React.FC<Props> = ({ countries, active, state, handleSelect, errorMsg, validateInputs }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
        <Autocomplete
            multiple
            classes={{ root: 'select-country__autocomplete' }}
            options={countries}
            disabled={active}
            value={state.countries.filter((country: ICountry) => country.name !== 'Global')}
            disableCloseOnSelect
            getOptionLabel={(option: ICountry) => (option.name === 'Global' ? '' : option.name)}
            onChange={(event: React.ChangeEvent<{}>, value: ICountry | ICountry[]) => {
                handleSelect(value, 'countries');
            }}
            onBlur={validateInputs}
            renderOption={(option, { selected }) => (
                <>
                    {option.name !== 'Global' && (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </React.Fragment>
                    )}
                </>
            )}
            renderInput={(params) => (
                <TextField
                    classes={{ root: `preaudit-input__container ${active && 'preaudit-input__container--disabled'}` }}
                    {...params}
                    variant="filled"
                    label="Countries"
                    color="secondary"
                    error={errorMsg?.length ? true : false}
                    helperText={errorMsg?.length ? errorMsg : null}
                    className={errorMsg?.length ? 'alert-border' : ''}
                />
            )}
        />
    );
};

interface LinkStateToProps {
    countries: ICountry[];
}

const mapStateToProps = (state: AppState): LinkStateToProps => ({
    countries: state.clientReducer.countries
});

export default connect<LinkStateToProps, {}, any, AppState>(mapStateToProps)(MultipleSelect);
