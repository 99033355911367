import React from 'react';
import { IVerticalCategory, IQuestion } from '../../../interfaces';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IAuditType } from '../../../interfaces/audittype';

interface IProps {
    categories: IVerticalCategory[];
    auditType?: IAuditType;
}
export default function QuestionsSummary(props: IProps) {
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    enum Answer {
        'yes' = 1,
        'no',
        'n/a',
        'partially'
    }
    enum Color {
        'green' = 1,
        'red',
        'gray',
        'lightgreen'
    }
    enum auditType {
        CROPreAudit = 1,
        CROMaturity = 2,
        iActivate = 3
    }

    return (
        <>
            {props.categories.length > 0 ? (
                <div className="question-summary--container">
                    {props.categories.map((row: IVerticalCategory, index: number) => (
                        <Accordion
                            key={index}
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls={`panel${index}bh-content`}
                                id={`panel${index}bh-header`}
                                className="accordionTitle"
                            >
                                {row.name}
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer className="preAudit-Summary--table" component={Paper}>
                                    <Table stickyHeader size="small" aria-label="customized table">
                                        <TableHead>
                                            <TableRow className="preAudit-Summary--highlight">
                                                <TableCell className="preAudit-Summary--head" align="left">
                                                    {props.auditType?.id === auditType.CROMaturity ? 'Maturity Level' : 'Topic'}
                                                </TableCell>
                                                <TableCell className="preAudit-Summary--head" align="left">
                                                    Question
                                                </TableCell>
                                                <TableCell className="preAudit-Summary--head" align="left">
                                                    Answer
                                                </TableCell>
                                                {props.auditType?.id !== auditType.CROMaturity && (
                                                    <TableCell className="preAudit-Summary--head" align="right">
                                                        Score
                                                    </TableCell>
                                                )}

                                                {props.auditType?.id !== auditType.CROMaturity && (
                                                    <TableCell className="preAudit-Summary--head" align="right">
                                                        MaxScore
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row.questions.map((rowitem: IQuestion, qindex: number) => (
                                                <TableRow
                                                    key={qindex}
                                                    className={`q_row--${
                                                        Color[rowitem.answer.answerOptionId ?? Color.gray]
                                                    }`}
                                                >
                                                    <TableCell align="left">
                                                        {rowitem.topic.name.charAt(0).toUpperCase() +
                                                            rowitem.topic.name.slice(1)}
                                                    </TableCell>
                                                    <TableCell align="left">{rowitem.text}</TableCell>

                                                    <TableCell align="left">
                                                        <div className="circle--answer"></div>
                                                        <p>{Answer[rowitem.answer.answerOptionId ?? Answer['n/a']]}</p>
                                                    </TableCell>
                                                    {props.auditType?.id !== auditType.CROMaturity && (
                                                        <TableCell align="right">{rowitem.answer.score}</TableCell>
                                                    )}
                                                    {props.auditType?.id !== auditType.CROMaturity && (
                                                        <TableCell align="right">
                                                            {rowitem.answer.answerOptionId === 3 ? 0 : rowitem.maxScore}
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            ) : null}
        </>
    );
}
