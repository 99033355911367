import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../Redux/store';

const NotAuthorized: React.FC = () => {
    return (
        <div>
            <div className="landing__text">You are not authorized!</div>
            <div className="landing__subtext">Please contact your administrator.</div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        accountState: state.accountReducer
    };
};

export default connect(mapStateToProps)(NotAuthorized);
