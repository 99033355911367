import React from 'react';
import { ICountry, IVertical, IVerticalCategory } from '../../../../interfaces'


interface passedState {
  client: string;
  countries: ICountry[];
  url: string;
  vertical: string;
}

interface Props {
  verticals: IVertical[];
  state: passedState;
  auditTypeId: string;
}
const VerticalsDescription: React.FC<Props> = ({ state, verticals, auditTypeId }) => {
  let selectedVertical = verticals.find(function (vertical) {
    return vertical.name === state.vertical;
  });
  return (
    <div className="verticalsDescription">
      <div className="verticalsDescription">
        <h3 className="verticalsDescription__title">Key Areas</h3>
        {!state.vertical.length && <p>Please select a Vertical</p>}
        <div className="verticalsDescription__list">
          {selectedVertical?.categories
            .filter((category: IVerticalCategory) => category.auditType.id.toString() === auditTypeId)
            .map((category: IVerticalCategory, index: number) => {
            return (
              <div className="verticalsDescription__item" key={index}>
                <h4 className="verticalsDescription__item__title">{category.name}</h4>
                <div className="verticalsDescription__item__text">{category.description}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default VerticalsDescription