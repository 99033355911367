import { useRouteMatch } from "react-router-dom";

type AuditParamKeys = 'auditType' | 'preAuditId';

type AuditParams = Record<AuditParamKeys, string>;

type Params = Partial<AuditParams> & {
};

type ROUTE_FORMAT =
    | '/audit/create/:auditType' // create mode
    | `/audit/:auditType/:preAuditId/summary`
    | `/audit/:auditType/:preAuditId/report`
    | `/audit/:auditType/:preAuditId/:category`;

const MATCH_ROUTES: Record<ROUTE_FORMAT, number> = {
    '/audit/create/:auditType': 1,
    '/audit/:auditType/:preAuditId/summary': 1,
    '/audit/:auditType/:preAuditId/report': 1,
    '/audit/:auditType/:preAuditId/:category': 1,
};

const path = Object.keys(MATCH_ROUTES);

const useAudit = () => {

    const matched = useRouteMatch<Params>({
        path,
        exact: true,
    });

    const { auditType, preAuditId } =
        matched?.params || {};

    return { auditType, preAuditId };
};

export default useAudit;