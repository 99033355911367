import { useCallback } from 'react';
import { IVertical, IVerticalCategory } from '../../../../interfaces';

const useStatusDisabled = () => {
    const statusDisabled = useCallback(
        (index: number, actionCategoryId: number | undefined, categories: string[], currentVertical: IVertical) => {
            if (actionCategoryId === undefined) return false;

            const actionCategoryName = currentVertical?.categories.find(
                (c: IVerticalCategory) => c.id === actionCategoryId
            )
                ? currentVertical.categories.filter(
                      (category: IVerticalCategory) => category.id === actionCategoryId
                  )[0].name
                : undefined;

            const actionCategoryIndex = actionCategoryName && categories?.indexOf(actionCategoryName);

            if (!actionCategoryIndex || index > actionCategoryIndex) {
                return true;
            }
            return false;
        },
        []
    );

    return { statusDisabled };
};

export default useStatusDisabled;
