import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { LoadClients, deleteClient, postClient, putClient } from '../../../../../services/clientService';
import { State } from '../../../../../Redux/Reducers/routerReducer';
import { store } from '../../../../../Redux/store';
import { Loader } from '../../../../Loader';
import CreateClient from './components/CreateClient';
import UpdateClient from './components/UpdateClient';
import { AlertDialog } from '../../../../AlertDialog';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { IClient, IUser } from '../../../../../interfaces';
import SearchBar from 'material-ui-search-bar';
import useAdminPermissionsCheck from '../../hooks/usePermissionsCheck';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { ButtonTooltip } from '../../../../ButtonTooltip';

interface OwnProps {}
type Props = OwnProps & LinkStateToProps;
const Clients: FC<Props> = ({ clients, accountUser }) => {
    const [filteredClients, setFilteredClients] = useState<IClient[]>([]);
    const [searched, setSearched] = useState<string>('');
    const [hasSearchResults, setHasSearchResults] = useState<boolean>(true);

    const [isLoading, setIsLoading] = useState(true);
    const newClient = {
        name: '' as string,
        description: '' as string,
        id: 0 as number,
        isDeleted: false
    };

    const { hasCreateClientAccess, hasDeleteClientAccess, hasUpdateClientAccess } = useAdminPermissionsCheck();

    const handleChange = async (client: IClient, action: string) => {
        switch (action) {
            case 'edit':
                await putClient(client);
                break;
            case 'create':
                await postClient(client);
                break;
            case 'delete':
                await deleteClient(client.id);
                break;
        }
        store.dispatch(LoadClients());
    };

    const requestSearch = (searchedVal: string) => {
        const filteredRows = clients.filter((row) => {
            return (
                row.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.description.toLowerCase().includes(searchedVal.toLowerCase())
            );
        });
        if (!filteredRows.length) {
            setHasSearchResults(false);
        } else {
            setHasSearchResults(true);
        }
        setFilteredClients(filteredRows);
    };

    const cancelSearch = () => {
        setSearched('');
        requestSearch(searched);
    };

    useEffect(() => {
        let isSubscribed: boolean = true;
        (async () => {
            await LoadClients();
            if (isSubscribed) {
                setIsLoading(false);
            }
        })();
        return () => {
            isSubscribed = false;
        };
    }, []);

    useEffect(() => {
        setFilteredClients(clients);
    }, [clients]);

    if (isLoading) {
        return <Loader />;
    } else
        return (
            <>
                <div className="admin-page--header">
                    <h1>Clients</h1>
                    <div className="search-bar-container">
                        <SearchBar
                            className="search-bar-clients"
                            value={searched}
                            onChange={(searchVal) => requestSearch(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                        />
                        {hasCreateClientAccess(accountUser) ? (
                            <CreateClient client={newClient} handleAgree={(c: IClient) => handleChange(c, 'create')} />
                        ) : (
                            <CreateClient client={newClient} handleAgree={() => {}} disabled={true} />
                        )}
                    </div>
                </div>

                <Grid className="user--page" container alignContent="flex-start">
                    <div className="user--table__container" style={{ marginTop: '-1vw' }}>
                        {hasSearchResults ? (
                            <Table className="table user--table" aria-label="customized table" stickyHeader>
                                <TableHead>
                                    <TableRow classes={{ root: 'user--table__header__row' }}>
                                        <TableCell classes={{ root: 'user--table__header__cell' }}>
                                            Client Name
                                        </TableCell>
                                        <TableCell classes={{ root: 'user--table__header__cell' }}>
                                            Description
                                        </TableCell>
                                        <TableCell classes={{ root: 'user--table__header__cell' }} align="center">
                                            Edit
                                        </TableCell>
                                        <TableCell classes={{ root: 'user--table__header__cell' }} align="center">
                                            Delete
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredClients.map((client: IClient, index: number) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                className={
                                                    'user--table__body__row' +
                                                    (client.isDeleted ? ' inactive' : '')
                                                }
                                            >
                                                <TableCell classes={{ root: 'user--table__body__cell' }}>
                                                    {client.name}
                                                </TableCell>
                                                <TableCell classes={{ root: 'user--table__body__cell' }}>
                                                    {client.description}
                                                </TableCell>
                                                <TableCell classes={{ root: 'user--table__body__cell' }} align="center">
                                                    {hasUpdateClientAccess(accountUser) ? (
                                                        <UpdateClient
                                                            client={client}
                                                            handleAgree={(c: IClient) => handleChange(c, 'edit')}
                                                        />
                                                    ) : (
                                                        <UpdateClient
                                                            disabled={true}
                                                            client={client}
                                                            handleAgree={() => {}}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell classes={{ root: 'user--table__body__cell' }} align="center">
                                                    {hasDeleteClientAccess(accountUser) ? (
                                                        <AlertDialog
                                                            buttonTooltipTitle={'Delete client'}
                                                            buttonTooltipTitleDisabled={
                                                                'Client has already been removed'
                                                            }
                                                            handleAgree={() => handleChange(client, 'delete')}
                                                            entity={client}
                                                            disabled={client.isDeleted}
                                                            dialogTitle={
                                                                'Are you sure you want to delete ' + client.name + '?'
                                                            }
                                                            deleteMessage="Delete Client"
                                                            dialogSubtitle={
                                                                '(any existing audits belonging to ' +
                                                                client.name +
                                                                ' will not be deleted.)'
                                                            }
                                                        />
                                                    ) : (
                                                        <ButtonTooltip
                                                            tooltipTitle={'Delete client'}
                                                            isDisabled={true}
                                                            titleDisabled={'Not enough permissions'}
                                                        >
                                                            <IconButton
                                                                disabled={true}
                                                                className="text-error"
                                                                onClick={() => {}}
                                                            >
                                                                <DeleteOutlinedIcon />
                                                            </IconButton>
                                                        </ButtonTooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            <h3 className="no-results">No matching results</h3>
                        )}
                    </div>
                </Grid>
            </>
        );
};

interface LinkStateToProps {
    clients: IClient[];
    accountUser?: IUser;
}
const mapStateToProps = (state: any): LinkStateToProps => ({
    clients: state.adminReducer.clients,
    accountUser: state.accountReducer.accountUser
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(Clients);
