import { axiosInstance } from './axiosConfig';

// Request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
