import { IRoute } from './../../interfaces/';
import { GET_ROUTES } from '../Actions/routerActions';
import { ROUTES } from '../../Routes/routesConfig';

export type State = {
    readonly routes: IRoute[];
};

const initialState: State = {
    routes: [...ROUTES]
};

export const routerReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case GET_ROUTES:
            return { ...state, routes: action.payload };
        default:
            return { ...state };
    }
};
