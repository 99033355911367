import { IRoute } from '../interfaces';
import { RootAppComponentWithRoutes, RootRouteComponentWithSubRoutes } from '../wrappers/routes';
import { FEATURES_ROUTES } from '../components/routes';
export const ROUTES: IRoute[] = [
    {
        key: 'ROOT',
        path: '/',
        title: 'App',
        component: RootAppComponentWithRoutes,
        routes: [
            {
                key: 'FEATURES_WRAPPER',
                path: '/',
                title: '',
                component: RootRouteComponentWithSubRoutes,
                routes: [...FEATURES_ROUTES]
            }
        ]
    }
];
