import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { matchPath } from 'react-router';

import { AppState } from '../../Redux/store';
import { IPermission, IUser } from '../../interfaces/';
import * as authService from '../../services/authService';
import BasicMenu from '../BasicMenu/BasicMenu';

// Material Ui
import { Button } from '@material-ui/core';
import usePermissionsCheck from '../../hooks/usePermissionsCheck';

type Props = LinkStateToProps;

const MainHeader: React.FC<Props> = ({ accountUser }) => {
    //BasicMenu state:
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isHovered, setIsHovered] = useState(false);
    const open = Boolean(anchorEl);

    const { canAccessAdministration } = usePermissionsCheck();

    const handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsHovered(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsHovered(!isHovered);
    };

    let creatorPermission = accountUser?.permissions.filter(
        (perm: IPermission) => perm.command === 'CreateAudits'
    ).length;
    const location = useLocation();
    const match = matchPath(location.pathname, {
        path: '/audit/:auditType/:preAuditId/:category',
        exact: true,
        strict: true
    });

    const adminArea = location.pathname.includes('/admin/');
    const createArea = location.pathname.includes('create');
    const reportArea = location.pathname.includes('report');
    const questionsForm =
        match?.isExact && !location.pathname.includes('summary') && !location.pathname.includes('report');

    const headerClass = clsx({
        header: true,
        'admin-area': adminArea,
        'create-area': createArea,
        'questions-area': questionsForm
    });

    return (
        <header className={headerClass}>
            <div className="header--logo">
                <Link className="link" to="/">
                    <img src={require('../../assets/images/Logo-Audit-Halcom.png')} alt="Logo" />
                </Link>
            </div>
            <div className="header--middleSection">
                {!reportArea && !questionsForm ? (
                    <>
                        <div className="header--middleSection--button">
                            <NavLink to="/">
                                <Button
                                    className={`btn btn_basic ${adminArea ? '' : 'active'}`}
                                    variant="contained"
                                >
                                    Audits
                                </Button>
                            </NavLink>
                        </div>
                        {canAccessAdministration(accountUser) ? (
                            <div className="header--middleSection--button">
                                <NavLink to="/admin/">
                                    <Button
                                        className={`btn btn_basic ${adminArea ? 'active' : ''}`}
                                        variant="contained"
                                    >
                                        Administration
                                    </Button>

                                </NavLink>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
            <div className="header--endSection">
                <div className="header--endSection--button">
                    {creatorPermission && !createArea ? (
                        <>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onMouseOver={handleMouseOver}
                                style={{
                                    backgroundColor: isHovered ? '#5b19c4' : 'black',
                                    borderColor: isHovered ? '#5b19c4' : 'black'
                                }}
                                className="btn btn_highlight btn_highlight--invert"
                                variant="contained"
                            >
                                Create Audit
                            </Button>
                            <BasicMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
                        </>
                    ) : ''}
                </div>

                <div className="header--endSection--button">
                    <Button
                        variant="contained"
                        className="btn btn_outline btn_outline--invert"
                        onClick={() => {
                            authService.logout();
                        }}
                    >
                        Log out
                    </Button>
                </div>
            </div>
        </header>
    );
};
interface LinkStateToProps {
    accountUser?: IUser;
}
const mapStateToProps = (state: AppState): LinkStateToProps => ({
    accountUser: state.accountReducer.accountUser
});

export default connect(mapStateToProps)(MainHeader);
