import { axiosInstance as http } from './axiosConfig';

// GET
export async function GET<T>(url: string, params?: object, body?: object): Promise<T> {
    try {
        const { data } = await http.get(url, { params, data: body });
        return Promise.resolve(data);
    } catch (error) {
        throw error;
    }
}

// POST
export async function POST<T>(url: string, value?: any): Promise<T> {
    try {
        const { data } = await http.post(url, value);
        return Promise.resolve(data);
    } catch (error) {
        throw error;
    }
}

// PUT
export async function PUT<T>(url: string, value?: any): Promise<T> {
    try {
        const { data } = await http.put(url, value);
        return Promise.resolve(data);
    } catch (error) {
        throw error;
    }
}

// DELETE
export async function DELETE<T>(url: string, params?: any, data?: any): Promise<T> {
    try {
        const { response } = await http.delete(url, { params, data });
        return Promise.resolve(response);
    } catch (error) {
        throw error;
    }
}
