import * as http from './http';
import { ApiBaseUrl } from '../config';
import { GET_CLIENTS_REQUEST, GET_CLIENTS_FAILURE, StartGetClientsSuccess } from '../Redux/Actions/admin';
import { store, AppState } from '../Redux/store';
import { ThunkAction } from 'redux-thunk';
import { IClient } from '../interfaces';

export const LoadClients = (): ThunkAction<void, AppState, undefined, any> => async (dispatch, getState) => {
    store.dispatch({
        type: GET_CLIENTS_REQUEST
    });
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/clients`);
        store.dispatch(StartGetClientsSuccess(response));
    } catch (e) {
        store.dispatch({
            type: GET_CLIENTS_FAILURE,
            error: 'Failed to load clients'
        });
    }
};

export async function deleteClient(clientId: number) {
    try {
        await http.DELETE<any>(`${ApiBaseUrl}/clients/${clientId}`);
    } catch (error) {}
}

export async function postClient(client: IClient) {
    try {
        await http.POST<IClient>(`${ApiBaseUrl}/clients`, {
            id: client.id,
            name: client.name,
            description: client.description
        });
    } catch (error) {}
}

export async function putClient(client: IClient) {
    try {
        await http.PUT<IClient>(`${ApiBaseUrl}/clients`, {
            id: client.id,
            name: client.name,
            description: client.description
        });
    } catch (error) {}
}
