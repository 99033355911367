//routesConfig
import { IRoute } from '../../interfaces';
import { AuthorizationGuard } from '../../services/route-guards';
import { PreAudits } from '../Pages/preAudits';
import { NotAuthorized } from '../NotAuthorized';
import { CreatePreAudit } from '../Pages/createPreAudit';
import { PreAuditForm } from '../Pages/preAuditForm';
import PreAuditSummary from '../Pages/preAuditSummary/PreAuditSummary';
import PreAuditReport from '../Pages/preAuditReport/PreAuditReport';
import { Administration } from '../Pages/administration';
import { Clients } from '../Pages/administration/components/ClientManagement';
import { UserRoles } from '../Pages/administration/components/UserManagement';
import { Roles } from '../Pages/administration/components/RolesManagement';
import {
    ACCESS_CLIENT_MANAGEMENT_PERMISSIONS,
    ACCESS_ROLES_MANAGEMENT_PERMISSIONS,
    ACCESS_USER_MANAGEMENT_PERMISSIONS,
    CREATE_AUDITS_PERMISSIONS,
    READ_AUDITS_PERMISSIONS,
    READ_CLIENTS_PERMISSIONS,
    READ_USERS_PERMISSIONS
}
    from '../../services/route-guards/config';

export const FEATURES_ROUTES: IRoute[] = [
    {
        path: '/not-authorized',
        key: 'NOT_AUTHORIZED',
        title: 'Not Authorized',
        exact: true,
        component: NotAuthorized
    },
    {
        path: '/',
        key: 'AUDITS_PAGE',
        title: 'Audits page',
        exact: true,
        component: AuthorizationGuard(PreAudits, READ_AUDITS_PERMISSIONS)
    },
    {
        path: '/audit/create/:auditType',
        key: 'CREATE_AUDIT',
        title: 'Create Audit',
        exact: true,
        component: AuthorizationGuard(CreatePreAudit, CREATE_AUDITS_PERMISSIONS)
    },
    {
        path: '/audit/:auditType/:preAuditId/summary',
        key: 'AUDIT_SUMMARY',
        title: 'Audit Summary',
        exact: true,
        component: AuthorizationGuard(PreAuditSummary, READ_AUDITS_PERMISSIONS)
    },
    {
        path: '/audit/:auditType/:preAuditId/report',
        key: 'AUDIT_REPORT',
        title: 'Audit Report',
        exact: true,
        component: AuthorizationGuard(PreAuditReport, READ_AUDITS_PERMISSIONS)
    },
    {
        path: '/audit/:auditType/:preAuditId/:category',
        key: 'CREATE_AUDIT',
        title: 'Create Audit',
        exact: true,
        component: AuthorizationGuard(PreAuditForm, CREATE_AUDITS_PERMISSIONS)
    },
    {
        path: '/admin',
        key: 'ADMIN_MAIN',
        title: 'Admin',
        exact: false,
        component: Administration,
        routes: [
            {
                path: '/admin/clients',
                key: 'ADMIN_CLIENTS',
                title: 'Admin - Clients',
                exact: false,
                component: AuthorizationGuard(Clients, ACCESS_CLIENT_MANAGEMENT_PERMISSIONS, READ_CLIENTS_PERMISSIONS),
            },
            {
                path: '/admin/users',
                key: 'ADMIN_USERS',
                title: 'Admin - Users',
                exact: false,
                component: AuthorizationGuard(UserRoles, ACCESS_USER_MANAGEMENT_PERMISSIONS, READ_USERS_PERMISSIONS),
            },
            {
                path: '/admin/roles',
                key: 'ADMIN_ROLES',
                title: 'Admin - Roles',
                exact: false,
                component: AuthorizationGuard(Roles, ACCESS_ROLES_MANAGEMENT_PERMISSIONS),
            }
        ]
    }
];
