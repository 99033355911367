import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { useParams, Link } from 'react-router-dom';
import clsx from 'clsx';
import { getPreAuditSummary, getRecommendationsSummary } from './../../../services/preAuditService';
import { ISummary } from '../../../interfaces/preauditSummary';
import { IRecommendations } from '../../../interfaces/recommendations';
import { ICategory } from '../../../interfaces/category';
import WarningComponent from './WarningComponent';
import AlertComponent from './AlertComponent';
import { Loader } from '../../Loader';
import { Recommendation } from './Recommendation';
import { CategoryRecommendation } from './CategoryRecommendation';
import ScoresSummary from './ScoresSummary';
import QuestionsSummary from './QuestionsSummary';
import { Button, IconButton } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getAuditLabel } from '../../../utils/summaryExtensions';
import useAudit from '../../../hooks/useAudit';
import { ICategoryRecommendation } from '../../../interfaces/categoryRecommendation';

interface ParamTypes {
    preAuditId: string;
}

export default function PreAuditSummary() {
    const { preAuditId } = useParams<ParamTypes>();
    const [summary, setSummary] = useState<ISummary | any>({});
    const [recommendations, setRecommendations] = useState<any>({});
    const [categories, setCategories] = useState<any>({});
    const [showAlert, setShowAlert] = useState(false);/* 'your changes will be lost' alert */
    const [showWarning, setShowWarning] = useState(false);/* 'please save your changes' warning */
    const [auditLabel, setAuditLabel] = useState('');
    const formContainer = useRef() as MutableRefObject<HTMLDivElement>;

    const [areThereUnsavedScoreChanges, setAreThereUnsavedScoreChanges] = useState(false); /* whether thereare unsaved changes to any of the targets/benchmarks in the table */
    const [areThereUnsavedTextChanges, setAreThereUnsavedTextChanges] = useState(false); /* whether there are unsaved changes in any of the (default) recommendation texts */
    const [wereThereAnyTextChanges, setWereThereAnyTextChanges] = useState(false); /* whether there were ANY changes done to the texts at all. */
    const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false);

    const { auditType } = useAudit();

    const onScrollUpClick = () => {
        formContainer.current.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const getReportClasses = clsx({ 'btn btn_highlight': true, 'btn_highlight--attract': (!areThereUnsavedTextChanges && !areThereUnsavedScoreChanges) });

    useEffect(() => {
        if (hasDataBeenFetched)
            return;

        (async function () {
            const auditSummary: ISummary | undefined = await getPreAuditSummary(+preAuditId);
            const recommendationsSummary: IRecommendations | undefined = await getRecommendationsSummary(+preAuditId);
            if (!hasDataBeenFetched) {
                setSummary(auditSummary);
                setRecommendations(recommendationsSummary);
                setHasDataBeenFetched(true);
            }
        })();
    }, [preAuditId, hasDataBeenFetched]);

    useEffect(() => {
        if (summary.vertical) {
            setCategories(summary.vertical.categories.map((category: ICategory) => category));
        }
    }, [summary]);

    useEffect(() => {
        setAuditLabel(getAuditLabel(summary));
    }, [summary]);

    return (
        <>
            {summary && categories?.length && recommendations.summary ? (
                <div className="preAudit-Summary--page">
                    <div className="preAudit-Summary--container score__section">
                        <div className="score__section__content">
                            <div>
                                <h3>Scores</h3>
                            </div>
                            <div
                                className={(!areThereUnsavedTextChanges) ? 'container--scores' : 'container--scores edit-disabled'}
                                onClick={() => {
                                    if (wereThereAnyTextChanges) {
                                        setShowAlert(true)
                                    }
                                }}
                            >
                                <ScoresSummary
                                    enableEdit={!wereThereAnyTextChanges}
                                    categories={categories}
                                    summary={summary}
                                    preAuditId={+preAuditId}
                                    setAreThereUnsavedScoreChanges={setAreThereUnsavedScoreChanges}
                                />
                                <div className="scores-table--saveBtn">
                                    {areThereUnsavedScoreChanges && (
                                        <IconButton
                                            color="secondary"
                                            onClick={() => {
                                                setAreThereUnsavedScoreChanges(false);
                                                setHasDataBeenFetched(false);
                                            }}
                                        >
                                            <span className="edit-text">Save</span>
                                            <CheckCircleIcon />
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                            <div className="preAudit-Summary--container">
                                <h3>Questions Summary</h3>
                                <QuestionsSummary categories={categories} auditType={summary.auditType} />
                            </div>
                            <AlertComponent
                                showAlert={showAlert}
                                setShowAlert={setShowAlert}
                                discardTextChanges={() => { 
                                    setAreThereUnsavedTextChanges(false); 
                                    setWereThereAnyTextChanges(false);
                                    setHasDataBeenFetched(false);// we're discarding the data - so we'll need to fetch them again
                                 }}
                            />
                            <div className="preAudit-Summary--container notes__container">
                                <h3>Notes</h3>
                                <p>{summary.notes}</p>
                            </div>
                        </div>
                    </div>
                    <div ref={formContainer} className="preAudit-Summary--container form__section">
                        <h1>Summary</h1>
                        <p className="preAudit-summary--text">
                            The {auditLabel} for {summary.client.name} is complete. Edit the scores and texts to your
                            liking. Finish the {auditLabel} by clicking on Get Report.
                        </p>
                        <div className="preAudit-Summary--container">
                            <div className="preAudit-Summary--buttons">
                                <Link
                                    to={`/audit/${auditType}/${preAuditId}/${categories[
                                        categories.length - 1
                                    ].name.toLowerCase()}`}
                                    onClick={(e) => {
                                        if (areThereUnsavedScoreChanges || areThereUnsavedTextChanges) {
                                            e.preventDefault();
                                            setShowWarning(true);
                                        }
                                    }}
                                >
                                    <Button variant="contained" className="btn btn_outline">
                                        Back
                                    </Button>
                                </Link>
                                <Link
                                    to={`/audit/${auditType}/${preAuditId}/report`}
                                    onClick={(e) => {
                                        if (areThereUnsavedScoreChanges || areThereUnsavedTextChanges) {
                                            e.preventDefault();
                                            setShowWarning(true);
                                        }
                                    }}
                                >
                                    <Button className={getReportClasses} variant="contained">
                                        Get Report
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        <WarningComponent showWarning={showWarning} setShowWarning={setShowWarning} />
                        {hasDataBeenFetched && (<div className="preAudit-Summary--recommendationSection">
                            <h3>PDF Texts</h3>
                            <Recommendation
                                preAuditId={+preAuditId}
                                summary={summary}
                                title="Summary"
                                section="summary"
                                setShowWarning={setShowWarning}
                                setAreThereUnsavedTextChanges={setAreThereUnsavedTextChanges}
                                setWereThereAnyTextChanges={setWereThereAnyTextChanges}
                                areThereUnsavedScoreChanges={areThereUnsavedScoreChanges}
                            />
                            <Recommendation
                                preAuditId={+preAuditId}
                                summary={summary}
                                title="Recommendations"
                                section="recommendations"
                                setShowWarning={setShowWarning}
                                setAreThereUnsavedTextChanges={setAreThereUnsavedTextChanges}
                                setWereThereAnyTextChanges={setWereThereAnyTextChanges}
                                areThereUnsavedScoreChanges={areThereUnsavedScoreChanges}
                            />

                            {summary.categoryRecommendations.map((categoryRecommendation: ICategoryRecommendation, qindex: number) => {
                                return (
                                    <CategoryRecommendation
                                        key={qindex}
                                        preAuditId={+preAuditId}
                                        categoryRecommendation={categoryRecommendation}
                                        setShowWarning={setShowWarning}
                                        setAreThereUnsavedTextChanges={setAreThereUnsavedTextChanges}
                                        setWereThereAnyTextChanges={setWereThereAnyTextChanges}
                                        areThereUnsavedScoreChanges={areThereUnsavedScoreChanges}
                                    />
                                );
                            })}

                            <Recommendation
                                preAuditId={+preAuditId}
                                summary={summary}
                                title="Full-Audit"
                                section="fullAudit"
                                setShowWarning={setShowWarning}
                                setAreThereUnsavedTextChanges={setAreThereUnsavedTextChanges}
                                setWereThereAnyTextChanges={setWereThereAnyTextChanges}
                                areThereUnsavedScoreChanges={areThereUnsavedScoreChanges}
                            />
                            <Recommendation
                                preAuditId={+preAuditId}
                                summary={summary}
                                title="Follow-Up"
                                section="followUp"
                                setShowWarning={setShowWarning}
                                setAreThereUnsavedTextChanges={setAreThereUnsavedTextChanges}
                                setWereThereAnyTextChanges={setWereThereAnyTextChanges}
                                areThereUnsavedScoreChanges={areThereUnsavedScoreChanges}
                            />

                            


                        </div>)}
                        <IconButton
                            aria-label="scroll-up"
                            className="btn btn_highlight btn_highlight--attract btn__scroll-up"
                            onClick={onScrollUpClick}
                        >
                            <ArrowUpwardIcon fontSize="large" />
                        </IconButton>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
