import React from 'react';

type Props = {
    children: any;
}
const SideMenu: React.FC<Props> = ({ children }: Props) => {
    return (
        <div className="side-menu">
            {children}
        </div>
    );
}

export default SideMenu;
