import React, { useState } from 'react';
import { AlertDialog } from '../../../../../AlertDialog';
import { deleteRole, putRole, LoadRoles } from '../../../../../../services/roleService';
import { IRole } from '../../../../../../interfaces';
import { store } from '../../../../../../Redux/store';
import UpdateRole from './UpdateRole';
import { ButtonTooltip } from '../../../../../ButtonTooltip';
import { IconButton } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

export default function RoleCard(props: any) {
    const [role] = useState<IRole>(props.role);

    const handleDelete = async (roleId: number) => {
        await deleteRole(roleId);

        store.dispatch(LoadRoles());
    };

    const handleUpdate = async (role: IRole) => {
        await putRole(role);

        store.dispatch(LoadRoles());
    };

    return (
        <div className="card-container">
            <div className="card-header">
                <h2>{role.name}</h2>

                <div className="card-actions">
                    {props.canEdit ? (
                        <UpdateRole
                            role={role}
                            permissions={props.permissions}
                            handleAgree={(r: IRole) => handleUpdate(r)}
                        />
                    ) : (
                        <UpdateRole role={role} permissions={props.permissions} handleAgree={() => { }} disabled={true} />
                    )}

                    {
                        props.canDelete ? (
                            <AlertDialog
                                buttonTooltipTitle={'Delete role'}
                                buttonTooltipTitleDisabled={'Role has already been removed'}
                                handleAgree={() => handleDelete(role.id)}
                                entity={role}
                                dialogTitle={'Are you sure you want to delete ' + role.name + '?'}
                                deleteMessage="Delete Role"
                            />
                        ) : (
                            <ButtonTooltip
                                tooltipTitle={'Delete role'}
                                isDisabled={true}
                                titleDisabled={'Not enough permissions'}
                            >
                                <IconButton
                                    disabled={true}
                                    className="text-error"
                                    onClick={() => { }}
                                >
                                    <DeleteOutlinedIcon />
                                </IconButton>
                            </ButtonTooltip>
                        )
                    }
                </div>
            </div>
            <div className="card-description">
                <p>{role.description}</p>
            </div>
        </div>
    );
}
