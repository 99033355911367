import { IUser } from '../../interfaces/user';
import { Dispatch } from 'redux';

export const SET_USER = 'SET_USER';

export interface SetUserAction {
    type: typeof SET_USER;
    payload: any;
}
export type UserActionTypes = SetUserAction;

export const setUser = (user: IUser): SetUserAction => ({
    type: SET_USER,
    payload: user
});

export const startSetUser = (user: IUser) => {
    return (dispatch: Dispatch<SetUserAction>) => {
        dispatch(setUser(user));
    };
};
