import { dev, prod, local } from './env';

export const env = process.env.REACT_APP_SaltEnv;

let config = local;
if (env === 'PRODUCTION') config = prod;
else if (env === 'DEV') config = dev;

// Config variables
export const envDisplay = config.envDisplay;
export const ApiBaseUrl = config.apiUrl;
