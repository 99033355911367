import { useCallback } from "react";
import usePermissionsCheck from "../../../../hooks/usePermissionsCheck";
import { IUser } from "../../../../interfaces";
import {
    CREATE_AUDITS_PERMISSIONS,
    READ_AUDITS_PERMISSIONS,
    UPDATE_AUDITS_PERMISSIONS
}
    from "../../../../services/route-guards/config";

const useAuditsPermissionsCheck = () => {
    const { hasPermissions } = usePermissionsCheck();

    const hasReadAuditsAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(READ_AUDITS_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasCreateAuditsAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(CREATE_AUDITS_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    const hasUpdateAuditsAccess = useCallback((accountUser: IUser | undefined) => {
        return hasPermissions(UPDATE_AUDITS_PERMISSIONS, accountUser);
    }, [hasPermissions]);

    return {
        hasReadAuditsAccess,
        hasCreateAuditsAccess,
        hasUpdateAuditsAccess
    };
};

export default useAuditsPermissionsCheck;