import React, { useEffect, useState, useRef } from 'react';

//Material-UI
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextField, IconButton } from '@material-ui/core';

//PreAudit
import { putCategoryMessage } from '../../../services/preAuditService';
import { ICategoryRecommendation } from '../../../interfaces/categoryRecommendation';

interface IProps {
    categoryRecommendation: ICategoryRecommendation | any;
    preAuditId: number;
    setShowWarning: any;
    setAreThereUnsavedTextChanges: any;
    setWereThereAnyTextChanges: any;
    areThereUnsavedScoreChanges: boolean;
}
export function CategoryRecommendation(props: IProps) {
    useEffect(() => {
        setText(props.categoryRecommendation.recommendation); 
    }, [props.categoryRecommendation, props.setAreThereUnsavedTextChanges]);

    const [text, setText] = useState(props.categoryRecommendation.recommendation);
    const [editText, setEditText] = useState<boolean>(false);
    const textRef = useRef<HTMLInputElement>(null);
    const [wereEditsMade, setWereEditsMade] = useState<boolean>(false); /* whether there were edits made to THIS component. Similar toAreThereUnsavedTextChanges, except that one regards ALL text field components */
    const handleTextEditSave = () => {
        setEditText(false);
        props.setAreThereUnsavedTextChanges(false);
        putCategoryMessage(props.preAuditId, props.categoryRecommendation.categoryId, text);
    };

    const handleTextChange = (e: any) => {
        setText(e.target.value);
        props.setAreThereUnsavedTextChanges(true);
        setWereEditsMade(true);
        props.setWereThereAnyTextChanges(true);
    };
    const handleActivateEdit = () => {
        if (props.areThereUnsavedScoreChanges) {
            props.setShowWarning(true);
        } else {
            setEditText(true);
            setTimeout(() => {
                if (textRef && textRef.current) {
                    textRef.current.focus();
                }
            }, 100);
        }
    };

    /* If this section is missing in the summary - render nothing but an empty container */
    if (!text)
        return(<div className="preAudit-Summary--container__recommendation" />);

    return (
        <div key={props.categoryRecommendation.categoryName}
            className="preAudit-Summary--container__recommendation">
            <div className={!editText ? 'recommendation--textfield edit-mode' : 'recommendation--textfield'}
                onClick={() => {
                    if (!editText) {
                        handleActivateEdit();
                    }
                }}
            >
                <TextField
                    id="standard-multiline-flexible"
                    multiline
                    maxRows={20}
                    classes={{ root: 'preaudit-input__container' }}
                    inputRef={textRef}
                    variant="filled"
                    value={text}
                    label={props.categoryRecommendation.categoryName}
                    // color="secondary"
                    disabled={editText ? false : true}
                    onChange={handleTextChange}
                />
                {!editText && (
                    <>
                        <EditIcon className='preAudit-Summary__edit-icon' onClick={() => handleActivateEdit()} />
                    </>
                )}
            </div>
            <div className="preAudit-Summary--container__edit">
                {wereEditsMade && editText && (
                    <div className="container-edit--save">
                        <IconButton onClick={() => handleTextEditSave()}>
                            <span className="edit-text">Save</span>
                            <CheckCircleIcon />
                        </IconButton>
                    </div>
                )}
            </div>
        </div>
    );
}
