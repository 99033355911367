import { Dispatch, Action } from 'redux';
import { IVertical } from '../../interfaces/vertical';
import { IClient } from '../../interfaces/client';

export type ClientActionTypes =
    | PreAuditsActionTypes
    | VerticalsActionTypes
    | PreCountriesTypes
    | PreAuditActionTypes
    | SetVerticalActionTypes
    | SetClientActionTypes
    | SetQuestionsActionTypes
    | SetCategoryActionActionTypes;

//Preaudits
type PreAuditsActionTypes = GetPreAuditsRequest | GetPreAuditsFailure | GetPreAuditsSuccess;

export const GET_PREAUDITS_REQUEST = 'GET_PREAUDITS_REQUEST';
export const GET_PREAUDITS_SUCCESS = 'GET_PREAUDITS_SUCCESS';
export const GET_PREAUDITS_FAILURE = 'GET_PREAUDITS_FAILURE';

interface GetPreAuditsRequest extends Action<typeof GET_PREAUDITS_REQUEST> { }
interface GetPreAuditsSuccess extends Action<typeof GET_PREAUDITS_SUCCESS> {
    payload: any[];
}
interface GetPreAuditsFailure extends Action<typeof GET_PREAUDITS_FAILURE> { }

export const GetPreAuditsRequest = (): GetPreAuditsRequest => ({
    type: GET_PREAUDITS_REQUEST
});
export const GetPreAuditsSuccess = (preAudits: any[]): GetPreAuditsSuccess => ({
    type: GET_PREAUDITS_SUCCESS,
    payload: preAudits
});
export const StartGetPreAuditsSuccess = (preAudits: any[]) => {
    return (dispatch: Dispatch<GetPreAuditsSuccess>) => {
        dispatch(GetPreAuditsSuccess(preAudits));
    };
};
export const GetPreAuditsFailure = (): GetPreAuditsFailure => ({
    type: GET_PREAUDITS_FAILURE
});

//countries
type PreCountriesTypes = GetCountriesRequest | GetCountriesSuccess | GetCountriesFailure;

export const GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

interface GetCountriesRequest extends Action<typeof GET_COUNTRIES_REQUEST> { }
interface GetCountriesSuccess extends Action<typeof GET_COUNTRIES_SUCCESS> {
    payload: any[];
}
interface GetCountriesFailure extends Action<typeof GET_COUNTRIES_FAILURE> { }

export const GetCountriesRequest = (): GetCountriesRequest => ({
    type: GET_COUNTRIES_REQUEST
});
export const GetCountriesSuccess = (countries: any[]): GetCountriesSuccess => ({
    type: GET_COUNTRIES_SUCCESS,
    payload: countries
});
export const StartGetCountriesSuccess = (countries: any[]) => {
    return (dispatch: Dispatch<GetCountriesSuccess>) => {
        dispatch(GetCountriesSuccess(countries));
    };
};
export const GetCountriesFailure = (): GetCountriesFailure => ({
    type: GET_COUNTRIES_FAILURE
});
type VerticalsActionTypes = GetVerticalsRequest | GetVerticalsSuccess | GetVerticalsFailure;

//Verticals
export const GET_VERTICALS_REQUEST = 'GET_VERTICALS_REQUEST';
export const GET_VERTICALS_SUCCESS = 'GET_VERTICALS_SUCCESS';
export const GET_VERTICALS_FAILURE = 'GET_VERTICALS_FAILURE';

interface GetVerticalsRequest extends Action<typeof GET_VERTICALS_REQUEST> { }
interface GetVerticalsSuccess extends Action<typeof GET_VERTICALS_SUCCESS> {
    payload: any[];
}
interface GetVerticalsFailure extends Action<typeof GET_VERTICALS_FAILURE> { }

export const GetVerticalsRequest = (): GetVerticalsRequest => ({
    type: GET_VERTICALS_REQUEST
});
export const GetVerticalsSuccess = (verticals: any[]): GetVerticalsSuccess => ({
    type: GET_VERTICALS_SUCCESS,
    payload: verticals
});
export const StartGetVerticalsSuccess = (verticals: any[]) => {
    return (dispatch: Dispatch<GetVerticalsSuccess>) => {
        dispatch(GetVerticalsSuccess(verticals));
    };
};
export const GetVerticalsFailure = (): GetVerticalsFailure => ({
    type: GET_VERTICALS_FAILURE
});

// start preAudit
type PreAuditActionTypes = RegisterPreAuditRequest | RegisterPreAuditSuccess | RegisterPreAuditFailure;

export const POST_PREAUDIT_REQUEST = 'POST_PREAUDIT_REQUEST';
export const POST_PREAUDIT_SUCCESS = 'POST_PREAUDIT_SUCCESS';
export const POST_PREAUDIT_FAILURE = 'POST_PREAUDIT_FAILURE';

interface RegisterPreAuditRequest extends Action<typeof POST_PREAUDIT_REQUEST> { }
interface RegisterPreAuditSuccess extends Action<typeof POST_PREAUDIT_SUCCESS> {
    payload: number;
}
interface RegisterPreAuditFailure extends Action<typeof POST_PREAUDIT_FAILURE> { }

export const RegisterPreAuditRequest = (): RegisterPreAuditRequest => ({
    type: POST_PREAUDIT_REQUEST
});
export const RegisterPreAuditSuccess = (preAuditId: number): RegisterPreAuditSuccess => ({
    type: POST_PREAUDIT_SUCCESS,
    payload: preAuditId
});
export const StartRegisterPreAuditSuccess = (preAuditId: number) => {
    return (dispatch: Dispatch<RegisterPreAuditSuccess>) => {
        dispatch(RegisterPreAuditSuccess(preAuditId));
    };
};
export const RegisterPreAuditFailure = (): RegisterPreAuditFailure => ({
    type: POST_PREAUDIT_FAILURE
});
// set selected vertical
type SetVerticalActionTypes = SetVerticalRequest | SetVerticalSuccess | SetVerticalFailure;

export const SET_VERTICAL_REQUEST = 'SET_VERTICAL_REQUEST';
export const SET_VERTICAL_SUCCESS = 'SET_VERTICAL_SUCCESS';
export const SET_VERTICAL_FAILURE = 'SET_VERTICAL_FAILURE';

interface SetVerticalRequest extends Action<typeof SET_VERTICAL_REQUEST> { }
interface SetVerticalSuccess extends Action<typeof SET_VERTICAL_SUCCESS> {
    payload: IVertical;
}
interface SetVerticalFailure extends Action<typeof SET_VERTICAL_FAILURE> { }

export const SetVerticalRequest = (): SetVerticalRequest => ({
    type: SET_VERTICAL_REQUEST
});
export const SetVerticalSuccess = (vertical: IVertical): SetVerticalSuccess => ({
    type: SET_VERTICAL_SUCCESS,
    payload: vertical
});

export const SetVerticalFailure = (): SetVerticalFailure => ({
    type: SET_VERTICAL_FAILURE
});

// set selected client
type SetClientActionTypes = SetClientRequest | SetClientSuccess | SetClientFailure;

export const SET_CLIENT_REQUEST = 'SET_CLIENT_REQUEST';
export const SET_CLIENT_SUCCESS = 'SET_CLIENT_SUCCESS';
export const SET_CLIENT_FAILURE = 'SET_CLIENT_FAILURE';

interface SetClientRequest extends Action<typeof SET_CLIENT_REQUEST> { }
interface SetClientSuccess extends Action<typeof SET_CLIENT_SUCCESS> {
    payload: IClient;
}
interface SetClientFailure extends Action<typeof SET_CLIENT_FAILURE> { }

export const SetClientRequest = (): SetClientRequest => ({
    type: SET_CLIENT_REQUEST
});
export const SetClientSuccess = (client: IClient): SetClientSuccess => ({
    type: SET_CLIENT_SUCCESS,
    payload: client
});

export const SetClientFailure = (): SetClientFailure => ({
    type: SET_CLIENT_FAILURE
});

//get questions
type SetQuestionsActionTypes = GetQuestionsRequest | GetQuestionsSuccess | GetQuestionsFailure;

export const GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE';

interface GetQuestionsRequest extends Action<typeof GET_QUESTIONS_REQUEST> { }
interface GetQuestionsSuccess extends Action<typeof GET_QUESTIONS_SUCCESS> {
    payload: any[];
}
interface GetQuestionsFailure extends Action<typeof GET_QUESTIONS_FAILURE> { }

export const GetQuestionsRequest = (): GetQuestionsRequest => ({
    type: GET_QUESTIONS_REQUEST
});
export const GetQuestionsSuccess = (questions: any[]): GetQuestionsSuccess => ({
    type: GET_QUESTIONS_SUCCESS,
    payload: questions
});
export const StartGetQuestionsSuccess = (questions: any[]) => {
    return (dispatch: Dispatch<GetQuestionsSuccess>) => {
        dispatch(GetQuestionsSuccess(questions));
    };
};
export const GetQuestionsFailure = (): GetQuestionsFailure => ({
    type: GET_QUESTIONS_FAILURE
});
//Set Action Category
type SetCategoryActionActionTypes = GetQuestionsRequest | GetQuestionsSuccess | GetQuestionsFailure;

export const GET_CATEGORY_ACTION_REQUEST = 'GET_CATEGORY_ACTION_REQUEST';
export const GET_CATEGORY_ACTION_SUCCESS = 'GET_CATEGORY_ACTION_SUCCESS';
export const GET_CATEGORY_ACTION_FAILURE = 'GET_CATEGORY_ACTION_FAILURE';

interface GetCategoryActionRequest extends Action<typeof GET_CATEGORY_ACTION_REQUEST> { }
interface GetCategoryActionSuccess extends Action<typeof GET_CATEGORY_ACTION_SUCCESS> {
    payload: number;
}
interface GetCategoryActionFailure extends Action<typeof GET_CATEGORY_ACTION_FAILURE> { }

export const GetCategoryActionRequest = (): GetCategoryActionRequest => ({
    type: GET_CATEGORY_ACTION_REQUEST
});
export const GetCategoryActionSuccess = (actionCategoryId: number): GetCategoryActionSuccess => ({
    type: GET_CATEGORY_ACTION_SUCCESS,
    payload: actionCategoryId
});
export const StartGetCategoryActionSuccess = (actionCategoryId: number) => {
    return (dispatch: Dispatch<GetCategoryActionSuccess>) => {
        dispatch(GetCategoryActionSuccess(actionCategoryId));
    };
};
export const GetCategoryActionFailure = (): GetCategoryActionFailure => ({
    type: GET_CATEGORY_ACTION_FAILURE
});

// set Current category
export const SET_CATEGORY_ID_SUCCESS = 'SET_CATEGORY_ID_SUCCESS';
interface SetCategorySuccess extends Action<typeof SET_CATEGORY_ID_SUCCESS> {
    payload: number;
}
export const SetCategorySuccess = (categoryId: number): SetCategorySuccess => ({
    type: SET_CATEGORY_ID_SUCCESS,
    payload: categoryId
});