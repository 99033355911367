import React, { useState } from 'react';
import { ISummary } from '../../../../interfaces';
import { handleGetPDF } from '../../../../services/preAuditService';
//Components
import MailModal from './MailModal';
//Material-UI
import { Button } from '@material-ui/core';

interface IProps {
    preAuditId: number;
    summary: ISummary;
    completed: boolean;
}

export default function ReportButtons(props: IProps) {
    const [showModal, setShowModal] = useState(false);
    let reportName = 'dentsu-Audit_' + props.summary.client + '.pdf';
    return (
        <>          <>
            <MailModal showModal={showModal} setShowModal={setShowModal} reportName={reportName} />
        </>

            <Button
                className="btn btn_highlight"
                onClick={() => {
                    setShowModal(true);
                }}
            >
                Send Report
                </Button>
            <Button
                className="btn btn_highlight btn-download"
                variant="contained"
                onClick={() => {
                    handleGetPDF(props.summary.client.name, props.preAuditId);
                }}
            >
                Download
                </Button>
        </>
    );
}
