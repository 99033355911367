import * as http from './http';
import { ApiBaseUrl } from '../config';

export const getAuditTypes = async () => {
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/audittypes`);
        return response;
    } catch (e) {}
};

export const getAuditTypeById = async (auditTypeId: number) => {
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/audittypes/${auditTypeId}`);
        return response;
    } catch (e) {}
};
