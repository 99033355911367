import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { State } from '../../../Redux/Reducers/accountReducer';
import { connect } from 'react-redux';
import { IPreAudit, IUser, ICountry } from '../../../interfaces';
import { handleGetPDF } from '../../../services/preAuditService';
import { LoadPreAudits } from '../../../services/fetchPreAudit';
import { formatForUrlParam } from '../../../utils/stringExtensions';
import { Loader } from '../../Loader';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Button,
    IconButton
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import BasicMenu from '../../BasicMenu/BasicMenu';
import SearchBar from 'material-ui-search-bar';
import useAuditsPermissionsCheck from './hooks/useAuditsPermissionsCheck';

interface Data {
    id: number;
    client: string;
    date: string;
    status: string;
    vertical: string;
    countries: string;
    link: string;
    consultant: string;
    auditType: string;
    auditTypeId: number;
}

function createData(
    id: number,
    client: string,
    date: string,
    status: string,
    vertical: string,
    countries: string,
    link: string,
    consultant: string,
    auditType: string,
    auditTypeId: number
): Data {
    return { id, client, date, status, vertical, countries, link, consultant, auditType, auditTypeId };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: HeadCell[] = [
    { id: 'client', numeric: true, disablePadding: false, label: 'Client' },
    { id: 'date', numeric: true, disablePadding: false, label: 'Date' },
    { id: 'vertical', numeric: true, disablePadding: false, label: 'Vertical' },
    { id: 'consultant', numeric: true, disablePadding: false, label: 'Consultant' },
    { id: 'countries', numeric: true, disablePadding: false, label: 'Countries' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
    { id: 'auditType', numeric: true, disablePadding: false, label: 'Type' }
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    isAdmin: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, isAdmin } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    let newHeadCells;
    isAdmin ? (newHeadCells = headCells) : (newHeadCells = headCells.filter((cell) => cell.id !== 'consultant'));
    return (
        <TableHead className="preAudits__table__header">
            <TableRow className="preAudits__table__header__row">
                {newHeadCells.map((headCell) => {
                    return (
                        <TableCell
                            className="preAudits__table__header__cell"
                            key={headCell.id}
                            align="left"
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className="preAudits__table--hidden">
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
                <TableCell align="center">Actions</TableCell>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '100%',
            margin: '0 auto',
            marginBottom: theme.spacing(2)
        }
    })
);
export enum Status {
    'in progress' = 1,
    'completed'
}

interface row {
    id: number;
    client: string;
    date: string;
    status: string;
    vertical: string;
    countries: string;
    link: string;
    consultant: string;
    auditType: string;
    auditTypeId: number;
}
interface OwnProps { }
type Props = OwnProps & LinkStateToProps;

const PreAudits: FC<Props> = ({ preAudits, accountUser }) => {
    const [rows, setRows] = useState<row[]>([]);
    const [newRows, setNewRows] = useState<row[]>([]);
    const classes = useStyles();
    const history = useHistory();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('client');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [searched, setSearched] = useState<string>('');
    const [hasSearchResults, setHasSearchResults] = useState<boolean>(true);
    //BasicMenu state:
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isHovered, setIsHovered] = useState(false);
    const open = Boolean(anchorEl);

    const { hasCreateAuditsAccess, hasReadAuditsAccess, hasUpdateAuditsAccess } = useAuditsPermissionsCheck();

    const handleMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setIsHovered(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsHovered(!isHovered);
    };

    const requestSearch = (searchedVal: string) => {
        const filteredRows = rows.filter((row) => {
            return (
                row.client.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.date.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.vertical.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.consultant.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.countries.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
                row.auditType.toLowerCase().includes(searchedVal.toLowerCase())
            );
        });
        if (!filteredRows.length) {
            setHasSearchResults(false);
        } else {
            setHasSearchResults(true);
        }
        setNewRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched('');
        requestSearch(searched);
    };

    useEffect(() => {
        let isSubscribed: boolean = true;
        (async () => {
            await LoadPreAudits();
            if (isSubscribed) {
                setIsLoading(false);
            }
        })();
        return () => {
            isSubscribed = false;
        };
    }, []);

    useEffect(() => {
        if (preAudits.length > 0) {
            const rows = preAudits.map((audit: IPreAudit) => {
                const countries = audit.countries.map((country: ICountry) => country.name).join(', ');
                let date: string = new Date(audit.date).toISOString().split('T')[0];
                const status = Status[audit.status];
                const actionCategory = audit.actionCategory === null ? '' : audit.actionCategory.name;
                return createData(
                    audit.id,
                    audit.client.name,
                    date,
                    status,
                    audit.vertical.name,
                    countries,
                    actionCategory,
                    audit.consultant.email,
                    audit.auditType.name,
                    audit.auditType.id
                );
            });
            setRows(rows);
            setNewRows(rows);
            setIsLoading(false);
        }
    }, [preAudits]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (isLoading) {
        return <Loader />;
    } else if (preAudits.length < 1) {
        return (
            <div className="preAudits--noData">
                <div>
                    <h1>Welcome to Pre-Audit</h1>
                    <p className="sub-headline">
                        There are no audits created yet.
                        {hasCreateAuditsAccess(accountUser) ? ' Go ahead and create your first pre-audit!' : ''}
                    </p>
                </div>
                {
                    hasCreateAuditsAccess(accountUser) ?
                        <>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onMouseOver={handleMouseOver}
                                style={{
                                    backgroundColor: isHovered ? '#5b19c4' : 'black',
                                    borderColor: isHovered ? '#5b19c4' : 'black'
                                }}
                                className="btn btn_highlight btn_highlight--invert"
                                variant="contained"
                            >
                                Create dentsu Audit
                            </Button>
                            <BasicMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
                        </> : ''
                }
            </div>
        );
    } else {
        return (
            <>
                {
                    hasReadAuditsAccess(accountUser) ?
                        <>
                            <SearchBar
                                className="search-bar"
                                value={searched}
                                onChange={(searchVal) => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                            />
                            <div className="preAudits--overview" style={{ padding: '1vw 2vw 2vw 2vw' }}>
                                <TableContainer className="preAudits__table__container">
                                    <div className="preAudits__table__container--inner">
                                        {hasSearchResults ? (
                                            <Table
                                                className="table preAudits__table"
                                                stickyHeader
                                                aria-labelledby="tableTitle"
                                                size="medium"
                                                aria-label="enhanced table"
                                            >
                                                <EnhancedTableHead
                                                    isAdmin={accountUser.isAdmin}
                                                    classes={classes}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={rows.length}
                                                />
                                                <TableBody className="preAudits__table__body">
                                                    {stableSort(newRows, getComparator(order, orderBy))
                                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        .map((row, index) => {
                                                            let link: string;
                                                            if (row.link === '') {
                                                                if (row.status === 'in progress') {
                                                                    link = 'summary';
                                                                } else {
                                                                    link = 'report';
                                                                }
                                                            } else {
                                                                link = row.link;
                                                            }

                                                            return (
                                                                <TableRow
                                                                    className="preAudits__table__body__row hover-grey"
                                                                    hover
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                    onClick={
                                                                        hasUpdateAuditsAccess(accountUser) ?
                                                                            () => {
                                                                                history.push(
                                                                                    `/audit/${row.auditTypeId}/${row.id}/${formatForUrlParam(link)}`
                                                                                );
                                                                            } : undefined}
                                                                >
                                                                    <TableCell
                                                                        className="preAudits__table__body__cell"
                                                                        align="left"
                                                                    >
                                                                        {row.client}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="preAudits__table__body__cell"
                                                                        align="left"
                                                                    >
                                                                        {row.date}
                                                                    </TableCell>

                                                                    <TableCell
                                                                        className="preAudits__table__body__cell"
                                                                        align="left"
                                                                    >
                                                                        {row.vertical}
                                                                    </TableCell>
                                                                    {accountUser.isAdmin && (
                                                                        <TableCell
                                                                            className="preAudits__table__body__cell"
                                                                            align="left"
                                                                        >
                                                                            {String(row.consultant).toLowerCase()}
                                                                        </TableCell>
                                                                    )}
                                                                    <TableCell
                                                                        className="preAudits__table__body__cell"
                                                                        align="left"
                                                                    >
                                                                        {row.countries}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="preAudits__table__body__cell"
                                                                        style={{ width: '8%' }}
                                                                        align="left"
                                                                    >
                                                                        <span className={'dot ' + row.status}></span>
                                                                        {row.status}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        className="preAudits__table__body__cell"
                                                                        align="left"
                                                                    >
                                                                        {row.auditType}
                                                                    </TableCell>
                                                                    {row.status === 'completed' ? (
                                                                        <TableCell
                                                                            className="preAudits__table__body__cell"
                                                                            align="center"
                                                                            style={{ padding: 0 }}
                                                                        >
                                                                            <IconButton
                                                                                onClick={(event) => {
                                                                                    event.stopPropagation();
                                                                                    handleGetPDF(row.client, row.id);
                                                                                }}
                                                                            >
                                                                                <PictureAsPdfIcon />
                                                                            </IconButton>{' '}
                                                                        </TableCell>
                                                                    ) : (
                                                                        <TableCell className="preAudits__table__body__cell">
                                                                            {' '}
                                                                        </TableCell>
                                                                    )}
                                                                </TableRow>
                                                            );
                                                        })}
                                                </TableBody>
                                            </Table>
                                        ) : (
                                            <h3 className="no-results">No matching results</h3>
                                        )}
                                    </div>
                                    {newRows.length > 10 && (
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            className="preAudits__table__pagination"
                                            count={newRows.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    )}
                                </TableContainer>
                            </div>
                        </>
                        : <span className="no-results">You do not have permissions to view audits</span>
                }
            </>
        );
    }
};
interface LinkStateToProps {
    preAudits: IPreAudit[];
    accountUser: IUser;
}

const mapStateToProps = (state: State): LinkStateToProps => ({
    preAudits: state.clientReducer.preAudits,
    accountUser: state.accountReducer.accountUser
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(PreAudits);
