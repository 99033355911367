import { Action } from 'redux';
import { IRoute } from './../../interfaces';

export const GET_ROUTES = 'GET_ROUTES';
interface GET_ROUTES extends Action<typeof GET_ROUTES> {
    payload: IRoute;
}

export const getRoutes = (route: IRoute): GET_ROUTES => ({
    type: GET_ROUTES,
    payload: route
});
