import React, { useEffect } from 'react';
import { SideMenu } from '../../SideMenu';
import { BrowserRouter as Router, Switch, Route, useHistory, useLocation, NavLink } from 'react-router-dom';
import { IAccountState, IRoute } from '../../../interfaces';
import { AppState } from '../../../Redux/store';
import { connect } from 'react-redux';
import usePermissionsCheck from '../../../hooks/usePermissionsCheck';

type Props = {
    routes?: IRoute[];
    accountState: IAccountState;
};

const Administration: React.FC<Props> = ({ routes, accountState }: Props) => {
    const history = useHistory();
    const location = useLocation();

    const { hasClientAccess, hasUserAccess, hasRolesAccess } = usePermissionsCheck();

    useEffect(() => {
        if (location?.pathname === '/admin' || location?.pathname === '/admin/') {
            if (hasClientAccess(accountState.accountUser)) {
                history.push('/admin/clients');
            } else if (hasUserAccess(accountState.accountUser)) {
                history.push('/admin/users');
            } else if (hasRolesAccess(accountState.accountUser)) {
                history.push('/admin/roles');
            } else {
                history.push('/not-authorized');
            }
        }
    }, [history, location, accountState, hasClientAccess, hasUserAccess, hasRolesAccess]);

    return (
        <>
            <div className="container">
                <Router>
                    <SideMenu>
                        {
                            hasClientAccess(accountState.accountUser) ?
                                <>
                                    <span className="side-menu__header">Client Management</span><ul>
                                        <li>
                                            <NavLink to='/admin/clients' className="item-container">
                                                <img src={require('../../../assets/images/clients.png')} alt="clients" />
                                                <h2 className="item-name">Clients</h2>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </>
                                : ''}
                        {
                            hasUserAccess(accountState.accountUser) || hasRolesAccess(accountState.accountUser) ?
                                <>
                                    <span className="side-menu__header">Access</span>
                                    <ul>
                                        {
                                            hasUserAccess(accountState.accountUser) ?
                                                <li>
                                                    <NavLink to='/admin/users' className="item-container">
                                                        <img src={require('../../../assets/images/user.png')} alt="users" />
                                                        <h2 className="item-name">Users</h2>
                                                    </NavLink>
                                                </li>
                                                : ''
                                        }
                                        {
                                            hasRolesAccess(accountState.accountUser) ?
                                                <li>
                                                    <NavLink to='/admin/roles' className="item-container">
                                                        <img src={require('../../../assets/images/user_roles.png')} alt="roles" />
                                                        <h2 className="item-name">Roles</h2>
                                                    </NavLink>
                                                </li>
                                                : ''
                                        }
                                    </ul>
                                </>
                                : ''
                        }
                    </SideMenu>
                    <div className="content-wrapper">
                        <Switch>
                            {routes?.map((route) => {
                                return (
                                    <Route
                                        key={route.key}
                                        exact={route.exact}
                                        path={route.path}
                                        component={() => (
                                            <route.component title={route.title} routes={route.routes} />
                                        )}
                                    />
                                );
                            })}
                        </Switch>
                    </div>
                </Router>
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState) => {
    return {
        accountState: state.accountReducer
    };
};

export default connect(mapStateToProps)(Administration);
