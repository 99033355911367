import {  
    AdminActionTypes,
    GET_USERS_SUCCESS,
    GET_CLIENTS_SUCCESS,
    GET_ROLES_SUCCESS,
    GET_PERMISSIONS_SUCCESS
 } from '../Actions/admin';

const initialState: any = {
    users: [],
    clients: [],
    roles: [],
    permissions: []
};

export const adminReducer = (state = initialState, action: AdminActionTypes): any => {
    switch (action.type) {
        case GET_USERS_SUCCESS:
            return { 
                ...state, 
                users: action.payload 
            };
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                clients: action.payload
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload
            };
        case GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: action.payload
            };
        default:
            return { ...state };
    }
};
