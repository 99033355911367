import React, { useEffect, FC } from 'react';
import './App.scss';
import * as authService from './services/authService';
import { IUser } from './interfaces/';
import { connect } from 'react-redux';
import { startSetUser } from './Redux/Actions/account';
import { AppState, store } from './Redux/store';
import { bindActionCreators, Dispatch } from 'redux';
import { LoadCountries, LoadVerticals } from './services/fetchPreAudit';
import { LoadClients } from './services/clientService';
import { LoadUserPermissions } from './services/userService';
import { LoadRoles } from './services/roleService';
import { LoadPermissions } from './services/roleService';
import { MainHeader } from './components/Header';
import { authenticationChecking } from './services/route-guards';
import { Route } from 'react-router-dom';
import { RootRouteComponentWithSubRoutes } from './wrappers/routes';
import { useRouteMatch } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import usePermissionsCheck from './hooks/usePermissionsCheck';

interface HomepageProps { }
type Props = HomepageProps & LinkDispatchToProps & LinkStateToProps;

const App: FC<Props> = ({ accountUser, startSetUser }) => {
    //get newest preaudit-data when opening home
    let location = true;
    if (useRouteMatch('/')?.isExact) {
        location = !location;
    }

    const { canAccessAdministration, hasUserAccess } = usePermissionsCheck();

    useEffect(() => {
        (async () => {
            const user = await authService.getIdentity();
            if (!user.email) {
                authService.login();
            } else {
                user.isAuthorized = true;
                if (hasUserAccess(user)) {
                    store.dispatch(LoadUserPermissions());
                }

                if (canAccessAdministration(user)) {
                    store.dispatch(LoadRoles());
                    store.dispatch(LoadPermissions());
                }

                store.dispatch(LoadVerticals());
                store.dispatch(LoadCountries());
                store.dispatch(LoadClients());
            }
            startSetUser(user);

        })();
    }, [
        startSetUser,
        location,
        canAccessAdministration,
        hasUserAccess
    ]);

    return (
        <StylesProvider injectFirst>
            <div className="App">
                {accountUser && (
                    <div className="Page">
                        <MainHeader />
                        <Route component={authenticationChecking(RootRouteComponentWithSubRoutes)} />
                    </div>
                )}
            </div>
        </StylesProvider>
    );
};

interface LinkStateToProps {
    accountUser?: IUser;
}
interface LinkDispatchToProps {
    startSetUser: (user: IUser) => void;
}

const mapStateToProps = (state: AppState): LinkStateToProps => ({
    accountUser: state.accountReducer.accountUser
});
const mapDispatchToProps = (dispatch: Dispatch): LinkDispatchToProps => ({
    startSetUser: bindActionCreators(startSetUser, dispatch)
});

export default connect<LinkStateToProps, LinkDispatchToProps, HomepageProps, AppState>(
    mapStateToProps,
    mapDispatchToProps
)(App);
