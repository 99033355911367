import {
    GET_PREAUDITS_SUCCESS,
    ClientActionTypes,
    GET_COUNTRIES_SUCCESS,
    GET_VERTICALS_SUCCESS,
    SET_VERTICAL_SUCCESS,
    POST_PREAUDIT_SUCCESS,
    GET_QUESTIONS_SUCCESS,
    SET_CATEGORY_ID_SUCCESS,
    GET_CATEGORY_ACTION_SUCCESS
} from '../Actions/client';
import * as NotesActions from './../Actions/notesActions';

const initialState: any = {
    preAudits: [],
    countries: [],
    verticals: [],
    selectedVertical: [],
    preAuditId: null,
    notes: [],
    currentCategoryId: null,
    actionCategoryId: null
};

export const clientReducer = (state = initialState, action: ClientActionTypes | any): any => {
    switch (action.type) {
        case GET_PREAUDITS_SUCCESS:
            return {
                ...state,
                preAudits: action.payload
            };
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload
            };
        case GET_VERTICALS_SUCCESS:
            return {
                ...state,
                verticals: action.payload
            };
        case POST_PREAUDIT_SUCCESS:
            return {
                ...state,
                preAuditId: action.payload
            };
        case SET_VERTICAL_SUCCESS:
            return {
                ...state,
                selectedVertical: action.payload
            };
        case NotesActions.Type.GET_NOTES_SUCCESS:
            return {
                ...state,
                notes: action.payload
            };
        case GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                questions: action.payload
            };
        case SET_CATEGORY_ID_SUCCESS:
            return {
                ...state,
                currentCategoryId: action.payload
            };
        case GET_CATEGORY_ACTION_SUCCESS:
            return {
                ...state,
                actionCategoryId: action.payload
            };

        default:
            return state;
    }
};
