import { createElement, lazy } from "react";
import useAudit from "../../../../hooks/useAudit";
import { AuditType } from "../../../../interfaces/audittype";
import { AuditTypeChartComponents } from "../interfaces";

const DefaultChartComponent = lazy(
    () => import('../containers/ChartContainer/DefaultChart/DefaultChart')
);

const CROMaturityChartComponent = lazy(
    () => import('../containers/ChartContainer/CROMaturityChart/CROMaturityChart')
);

const ChartComponents: AuditTypeChartComponents = {
    1: { component: DefaultChartComponent },
    2: { component: CROMaturityChartComponent },
    3: { component: DefaultChartComponent }
};

const useAuditTypeChartComponent = () => {

    const { auditType } = useAudit();

    const ChartComponent = ({ summary, setChart }: any) =>
        createElement(ChartComponents[Number(auditType) ?? AuditType.CROPreAudit].component, { summary, setChart });

    return {
        ChartComponent
    };
};

export default useAuditTypeChartComponent;