import React, { useState, useEffect } from 'react';
import { putBenchmark, putTarget } from '../../../services/preAuditService';
import { ICategory, ISummary, IVerticalCategory } from '../../../interfaces';
//Material-UI
import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { AuditType } from '../../../interfaces/audittype';

type MaturityLevel = {
    topicName: string;
    maturityLevelPercent: number;
};

interface IProps {
    summary: ISummary;
    categories: IVerticalCategory[];
    preAuditId: number;
    enableEdit: boolean;
    setAreThereUnsavedScoreChanges: any
}

type ScoresSummary = {
    allTargets: number[];
    targetTotal: number;
    benchmarkTotal: number;
    scoreTotal: number;
    maturityLevelArray: string[];
    nextMaturityLevelArray: string[];
};

export default function ScoresSummary(props: IProps) {
    const {
        allTargets: defaultAllTargets,
        targetTotal: defaultTargetTotal,
        benchmarkTotal: defaultBenchmarkTotal,
        scoreTotal,
        maturityLevelArray,
        nextMaturityLevelArray
    } = (props.summary.scores as ICategory[]).reduce<ScoresSummary>(
        (acc, currentScore: ICategory) => {
            // calculate allTargets
            acc.allTargets.push(currentScore.target);

            // calculate targetTotal
            acc.targetTotal += currentScore.target;

            // calculate benchmarkTotal
            acc.benchmarkTotal += currentScore.benchmark;

            // calculate scoreTotal
            acc.scoreTotal += currentScore.score;

            // calculate maturityLevelArray
            if (currentScore.currentTopic) {
                acc.maturityLevelArray.push(currentScore.currentTopic);
            }

            // calculate nextMaturityLevelArray
            if (currentScore.nextTopic) {
                acc.nextMaturityLevelArray.push(currentScore.nextTopic);
            }

            return acc;
        },
        {
            allTargets: [],
            targetTotal: 0,
            benchmarkTotal: 0,
            scoreTotal: 0,
            maturityLevelArray: [],
            nextMaturityLevelArray: []
        }
    );

    const [allTargets, setAllTargets] = useState(defaultAllTargets);
    const [targetTotal, setTargetTotal] = useState<number>(Number(defaultTargetTotal.toFixed(1)));
    const [allBenchmarks, setAllBenchmarks] = useState(defaultAllTargets);
    const [benchmarkTotal, setBenchmarkTotal] = useState<number>(Number(defaultBenchmarkTotal.toFixed(1)));

    useEffect(() => {
        setTargetTotal(Number(allTargets.reduce((a: number, b: any) => a + b, 0).toFixed(1)));
    }, [allTargets]);

    useEffect(() => {
        setBenchmarkTotal(Number(allBenchmarks.reduce((a: number, b: any) => a + b, 0).toFixed(1)));
    }, [allBenchmarks]);

    function isMaxScoreReached(index: any) {
        const maxScore = props.summary.scores[index].maxScore;
        const totalScore = props.summary.scores[index].totalScore;
        return totalScore / maxScore === 1;
    }

    function handleBenchmarkChange(value: any, index: number, categoryId: number) {
        const newBenchmarks = [...allBenchmarks];
        newBenchmarks[index] = parseFloat(value);
        setAllBenchmarks(newBenchmarks);
        putBenchmark(props.preAuditId, categoryId, value);
        props.setAreThereUnsavedScoreChanges(true);
    }
    function handleTargetChange(value: any, index: number, categoryId: number) {
        const newTargets = [...allTargets];
        newTargets[index] = parseFloat(value);
        setAllTargets(newTargets);
        putTarget(props.preAuditId, categoryId, value);
        props.setAreThereUnsavedScoreChanges(true);
    }

    function getTotalScoreInCategory() {
        const scoredInCategoryArr = props.summary.scores.map((score: any) => {
            return score.score;
        });
        return (
            (
                (scoredInCategoryArr.reduce((acc: number, cur: number) => acc + cur, 0) * 10) /
                scoredInCategoryArr.length
            ).toFixed(1) + '%'
        );
    }

    //returns maturity level with most occurrences in the Maturity Level col:
    function getDominantMaturityLevel(arr: string[]): string | null {
        if (arr.length === 0) return null;
        const elementFrequency: { [key: string]: number } = {};
        let mostFrequentElement = arr[0];
        let highestFrequencyCount = 1;
        for (let i = 0; i < arr.length; i++) {
            const el = arr[i];
            if (elementFrequency[el] === undefined) elementFrequency[el] = 1;
            else elementFrequency[el]++;
            if (elementFrequency[el] > highestFrequencyCount) {
                mostFrequentElement = el;
                highestFrequencyCount = elementFrequency[el];
            }
        }
        return mostFrequentElement;
    }

    return (
        <div className="preAudit-scores__container">
            {!props.enableEdit && (
                <>
                    <EditIcon className="preAudit-Summary__edit-icon" />
                    <div className="preAudit-Summary__edit-text__container">
                        <span className="preAudit-Summary__edit-text">Click to edit</span>
                    </div>
                </>
            )}
            <TableContainer className="preAudit-scores__table__container" component={Paper}>
                <Table size="small" classes={{ root: 'preAudit-scores__table' }} aria-label="customized table">
                    <TableHead classes={{ root: 'preAudit-scores__table__header' }}>
                        <TableRow>
                            <TableCell className="preAudit-scores__table__header__cell" align="left">
                                Category
                            </TableCell>
                            <TableCell className="preAudit-scores__table__header__cell" align="left">
                                {props.summary.auditType.id === AuditType.CROMaturity ? 'Maturity Level' : 'Score'}
                            </TableCell>
                            <TableCell className="preAudit-scores__table__header__cell" align="left">
                                {props.summary.auditType.id === AuditType.CROMaturity
                                    ? 'Next Maturity Level'
                                    : 'Benchmark'}
                            </TableCell>
                            <TableCell className="preAudit-scores__table__header__cell" align="left">
                                {props.summary.auditType.id === AuditType.CROMaturity
                                    ? '% Scored in Category'
                                    : 'Target'}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.categories.map((row: any, index: number) => (
                            <TableRow
                                key={index}
                                classes={{ root: isMaxScoreReached(index) ? 'green-bg' : 'hover-grey' }}
                            >
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">
                                    {props.summary.auditType.id === AuditType.CROMaturity
                                        ? isMaxScoreReached(index)
                                            ? ''
                                            : props.summary.scores[index].currentTopic
                                        : props.summary.scores[index].score.toFixed(1)}
                                </TableCell>
                                <TableCell align="left">
                                    {props.summary.auditType.id === AuditType.CROMaturity ? (
                                        isMaxScoreReached(index) ? (
                                            ''
                                        ) : (
                                            props.summary.scores[index].nextTopic
                                        )
                                    ) : (
                                        <TextField
                                            onChange={(e) => {
                                                if (Number(e.target.value) < 0) {
                                                    e.target.value = '0';
                                                } else if (Number(e.target.value) > 10) {
                                                    e.target.value = '10';
                                                }
                                                handleBenchmarkChange(e.target.value, index, row.id);
                                            }}
                                            type="number"
                                            defaultValue={props.summary.scores[index].benchmark}
                                            inputProps={{
                                                'aria-label': 'number',
                                                min: '0',
                                                max: '10',
                                                step: '0.1'
                                            }}
                                            disabled={props.enableEdit ? false : true}
                                            color="secondary"
                                        />
                                    )}
                                </TableCell>
                                <TableCell className="preAudit-Summary--scores--Input" align="left">
                                    {props.summary.auditType.id === AuditType.CROMaturity ? (
                                        <TextField
                                            onChange={(e) => {
                                                if (Number(e.target.value) < 0) {
                                                    e.target.value = '0';
                                                } else if (Number(e.target.value) > 100) {
                                                    e.target.value = '100';
                                                }
                                            }}
                                            onBlur={(e) => handleTargetChange(e.target.value, index, row.id)}
                                            defaultValue={`${(props.summary.scores[index].score * 10).toFixed(1)}%`}
                                            type="text"
                                            inputProps={{
                                                'aria-label': 'naked',
                                                min: '0',
                                                max: '100',
                                                step: '1'
                                            }}
                                            disabled={true}
                                            color="secondary"
                                        />
                                    ) : (
                                        <TextField
                                            onChange={(e) => {
                                                if (Number(e.target.value) < 0) {
                                                    e.target.value = '0';
                                                } else if (Number(e.target.value) > 10) {
                                                    e.target.value = '10';
                                                }
                                                handleTargetChange(e.target.value, index, row.id);
                                            }}
                                            defaultValue={props.summary.scores[index].target}
                                            type="number"
                                            inputProps={{
                                                'aria-label': 'naked',
                                                min: '0',
                                                max: '10',
                                                step: '0.1'
                                            }}
                                            disabled={props.enableEdit ? false : true}
                                            color="secondary"
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow className="preAudit-Summary--scores--total">
                            <TableCell align="left">
                                {props.summary.auditType.id === AuditType.CROMaturity ? 'Total' : 'Average'}
                            </TableCell>
                            <TableCell>
                                {props.summary.auditType.id === AuditType.CROMaturity
                                    ? getDominantMaturityLevel(maturityLevelArray)
                                    : (scoreTotal / props.categories.length).toFixed(1)}
                            </TableCell>
                            <TableCell align="left">
                                {props.summary.auditType.id === AuditType.CROMaturity
                                    ? getDominantMaturityLevel(nextMaturityLevelArray)
                                    : (benchmarkTotal / props.categories.length).toFixed(1)}
                            </TableCell>
                            <TableCell align="left">
                                {props.summary.auditType.id === AuditType.CROMaturity
                                    ? getTotalScoreInCategory()
                                    : (targetTotal / props.categories.length).toFixed(1)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
