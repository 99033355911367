import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { State } from '../../../Redux/Reducers/accountReducer';
import { useParams } from 'react-router-dom';
import { IPreAudit, IVertical, IVerticalCategory } from '../../../interfaces';
import { formatForUrlParam } from '../../../utils/stringExtensions';
import NotesField from './Notes';

// interface Props {}
interface OwnProps {
    currentPreAudit: IPreAudit;
    currentVertical: IVertical;
}

interface ParamTypes {
    preAuditId: string;
    category: string;
}

type Props = OwnProps & LinkStateToProps;
const ClientInfo: React.FC<Props> = ({ currentPreAudit, currentVertical }) => {
    const { preAuditId, category } = useParams<ParamTypes>();
    const [client, setClient] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [verticalCategory, setVerticalCategory] = useState<IVerticalCategory | undefined>();
    const getCorrectURL = (url: string): string => {
        let newURL = url;
        if (!url.includes('www.')) {
            newURL = 'www.' + newURL;
        }
        if (!url.includes('http')) {
            newURL = 'https://' + newURL;
        }
        return newURL;
    };
    useEffect(() => {
        setClient(currentPreAudit.client.name);
        setUrl(currentPreAudit.url);
        if (currentVertical) {
            let currentCategoryId: number = currentVertical.categories.filter(
                (vertical: IVerticalCategory) => formatForUrlParam(vertical.name) === category
            )[0].id;
            setVerticalCategory(
                currentVertical.categories.filter((vertical: IVerticalCategory) => vertical.id === currentCategoryId)[0]
            );
        }
    }, [currentPreAudit, currentVertical, verticalCategory, category]);
    return (
        <div className="question-table--infobox__content">
            <h1>{client}</h1>
            <h3>
                <a target="_blank" rel="noopener noreferrer" href={getCorrectURL(url)}>
                    {url}
                </a>
            </h3>
            <NotesField preAuditId={+preAuditId} />
            {verticalCategory && (
                <div className="verticalsDescription__item">
                    <h4 className="verticalsDescription__item__title">{verticalCategory.name}</h4>
                    <div className="verticalsDescription__item__text">{verticalCategory.description}</div>
                </div>
            )}
        </div>
    );
};
interface LinkStateToProps {
    currentCategoryId: number;
}

const mapStateToProps = (state: State): LinkStateToProps => ({
    currentCategoryId: state.clientReducer.currentCategoryId
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(ClientInfo);
