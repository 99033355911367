import { Tooltip } from '@material-ui/core';
import React from "react";

type Props = {
    children: any;
    isDisabled?: boolean;
    tooltipTitle: string;
    titleDisabled?: string;
};

const ButtonTooltip: React.FC<Props> = ({ children, isDisabled, tooltipTitle, titleDisabled }: Props) => {
    return (
        <Tooltip className={"button_tooltip" + (isDisabled ? ' inactive' : '')}
            title={isDisabled && titleDisabled ? titleDisabled : tooltipTitle}>
            <span>
                {children}
            </span>
        </Tooltip>
    );
}

export default ButtonTooltip;