import { ISummary } from "../interfaces";

export function getSummaryReportTitle(summary: ISummary | undefined): string {
    if (!summary) {
        return '';
    }

    switch (summary?.auditType.id) {
        case 1:
            return 'CRO Pre-Audit Scores';
        case 2:
            return 'Optimization Maturity Audit Scores';
        case 3:
            return 'iActivate Audit Scores';
        default:
            return `${summary?.auditType.name} Audit Scores`;
    }
};

/* depending on auditType, the actual audit might be labeled slightly differently */
export function getAuditLabel(summary: ISummary): string {
    if (!summary || !summary.auditType)
        return 'audit';

    switch (summary.auditType.id) {
        case 1:
            return 'pre-audit';
        case 2:
            return 'CRO Maturity Audit';
        case 3:
            return 'iActivate audit';
        default:
            return 'audit';
    }
}