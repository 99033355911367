import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { LoadUserPermissions, putUser } from '../../../../../services/userService';
import { State } from '../../../../../Redux/Reducers/routerReducer';
import { deleteUser } from '../../../../../services/userService';
import { store } from '../../../../../Redux/store';
import { IRole, IUpdateUser, IUser } from '../../../../../interfaces';
import { AlertDialog } from '../../../../AlertDialog';
import RolesView from './components/RolesView';
import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
import UpdateUser from './components/UpdateUser';
import useAdminPermissionsCheck from '../../hooks/usePermissionsCheck';
import { ButtonTooltip } from '../../../../ButtonTooltip';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

interface Data {
    id: number;
    name: string;
    email: string;
    roles: IRole[];
}
interface OwnProps {}
type Props = OwnProps & LinkStateToProps;
const UserRoles: FC<Props> = ({ users, roles, accountUser }) => {
    const [filteredUsers, setFilteredUsers] = useState<Data[]>([]);
    const [searched, setSearched] = useState<string>('');
    const [hasSearchResults, setHasSearchResults] = useState<boolean>(true);

    const { hasUpdateUserAccess, hasDeleteUserAccess } = useAdminPermissionsCheck();

    const handleDeleteClick = async (user: Data) => {
        await deleteUser(user.id);
        store.dispatch(LoadUserPermissions());
    };

    const mapUsersResult = (users: Data[]) => {
        return users.map((u) => {
            if (u.name === null) {
                u.name = u.email.substring(0, u.email.indexOf('@')).replace('.', ' ');
            }

            return u;
        });
    };

    const requestSearch = (searchedVal: string) => {
        const filteredRows = users.filter((u) => {
            return u.email.toLowerCase().includes(searchedVal.toLowerCase());
        });

        if (!filteredRows.length) {
            setHasSearchResults(false);
        } else {
            setHasSearchResults(true);
        }
        setFilteredUsers(filteredRows);
    };

    const cancelSearch = () => {
        setSearched('');
        requestSearch(searched);
    };

    const handleChange = async (user: IUpdateUser) => {
        await putUser(user);

        store.dispatch(LoadUserPermissions());
    };

    const hasUserBeenRemoved = (user: Data) => { return !user.roles.length; };

    useEffect(() => {
        setFilteredUsers(mapUsersResult(users));
    }, [users]);

    return (
        <>
            <div className="admin-page--header">
                <h1>Users</h1>
                <div className="search-bar-container">
                    <SearchBar
                        className="search-bar-clients"
                        value={searched}
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                    />
                </div>
            </div>

            <Grid className="user--page" container alignContent="flex-start">
                <div className="user--table__container" style={{ marginTop: '-1vw' }}>
                    {hasSearchResults ? (
                        <Table className="table user--table" aria-label="customized table" stickyHeader>
                            <TableHead>
                                <TableRow classes={{ root: 'user--table__header__row' }}>
                                    <TableCell classes={{ root: 'user--table__header__cell' }}>User Name</TableCell>
                                    <TableCell classes={{ root: 'user--table__header__cell' }}>Email</TableCell>
                                    <TableCell classes={{ root: 'user--table__header__cell' }} align="center">
                                        Roles
                                    </TableCell>
                                    <TableCell classes={{ root: 'user--table__header__cell' }} align="center">
                                        Edit
                                    </TableCell>
                                    <TableCell classes={{ root: 'user--table__header__cell' }} align="center">
                                        Delete
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.map((user: Data, index: number) => {
                                    return (
                                        <TableRow key={index} 
                                            classes={{ root: 'user--table__body__row' }}
                                            className={hasUserBeenRemoved(user) ? 'inactive' : ''}
                                        >
                                            <TableCell classes={{ root: 'user--table__body__cell user--table__body__name' }} >
                                                {user.name}
                                            </TableCell>
                                            <TableCell classes={{ root: 'user--table__body__cell' }}>
                                                {user.email.toLowerCase()}
                                            </TableCell>
                                            <TableCell classes={{ root: 'user--table__body__cell' }} align="center">
                                                <RolesView roles={user.roles} />
                                            </TableCell>
                                            <TableCell classes={{ root: 'user--table__body__cell' }} align="center">
                                                <UpdateUser
                                                    user={user}
                                                    roles={roles}
                                                    handleAgree={(u: IUpdateUser) => handleChange(u)}
                                                    disabled={hasUpdateUserAccess(accountUser) ? false : true}
                                                />
                                            </TableCell>

                                            <TableCell classes={{ root: 'user--table__body__cell' }} align="center">
                                                {hasDeleteUserAccess(accountUser) ? (
                                                    <AlertDialog
                                                        buttonTooltipTitle={'Delete user'}
                                                        buttonTooltipTitleDisabled={'User has already been removed'}
                                                        handleAgree={() => handleDeleteClick(user)}
                                                        entity={user}
                                                        disabled={hasUserBeenRemoved(user)}
                                                        dialogTitle={
                                                            'Are you sure you want to delete ' + user.name + '?'
                                                        }
                                                        deleteMessage="Delete User"
                                                    />
                                                ) : (
                                                    <ButtonTooltip
                                                        tooltipTitle={'Delete user'}
                                                        isDisabled={true}
                                                        titleDisabled={'Not enough permissions'}
                                                    >
                                                        <IconButton
                                                            disabled={true}
                                                            className="text-error"
                                                            onClick={() => {}}
                                                        >
                                                            <DeleteOutlinedIcon />
                                                        </IconButton>
                                                    </ButtonTooltip>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    ) : (
                        <h3 className="no-results">No matching results</h3>
                    )}
                </div>
            </Grid>
        </>
    );
};

interface LinkStateToProps {
    users: Data[];
    roles: IRole[];
    accountUser?: IUser;
}
const mapStateToProps = (state: any): LinkStateToProps => ({
    users: state.adminReducer.users,
    roles: state.adminReducer.roles,
    accountUser: state.accountReducer.accountUser
});

export default connect<LinkStateToProps, {}, OwnProps, State>(mapStateToProps)(UserRoles);
