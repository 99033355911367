import * as http from './http';
import { ApiBaseUrl } from '../config';
import { GET_USERS_REQUEST, GET_USERS_FAILURE, StartGetUsersSuccess } from '../Redux/Actions/admin';
import {
    StartRegisterPreAuditSuccess,
    POST_PREAUDIT_REQUEST,
    POST_PREAUDIT_FAILURE,
    SET_VERTICAL_REQUEST,
    SET_VERTICAL_FAILURE,
    SetVerticalSuccess,
    SET_CLIENT_REQUEST,
    SET_CLIENT_FAILURE,
    SetClientSuccess,
    GET_QUESTIONS_REQUEST,
    GET_QUESTIONS_FAILURE,
    StartGetQuestionsSuccess
} from '../Redux/Actions/client';
import { store, AppState } from '../Redux/store';
import { ThunkAction } from 'redux-thunk';
import { IUpdateUser } from '../interfaces';

export const LoadUserPermissions = (): ThunkAction<void, AppState, undefined, any> => async (dispatch, getState) => {
    dispatch({
        type: GET_USERS_REQUEST
    });
    try {
        const response = await http.GET<any>(`${ApiBaseUrl}/users`);
        store.dispatch(StartGetUsersSuccess(response));
    } catch (e) {
        dispatch({
            type: GET_USERS_FAILURE,
            error: 'Failed to load permissions'
        });
    }
};
export const createPreAudit =
    (data: any): ThunkAction<void, AppState, undefined, any> =>
    async (dispatch, getState) => {
        dispatch({
            type: POST_PREAUDIT_REQUEST
        });
        try {
            const response = await http.POST<any>(`${ApiBaseUrl}/preaudits`, data);
            store.dispatch(StartRegisterPreAuditSuccess(response));
            return response;
        } catch (e) {
            dispatch({
                type: POST_PREAUDIT_FAILURE,
                error: 'Failed to load permissions'
            });
        }
    };

// add role to user
export async function addRoleToUser(roleId: number, userId: number) {
    try {
        await http.POST<any>(`${ApiBaseUrl}/users/adduserrole?userId=${userId}&roleId=${roleId}`);
    } catch (error) {}
}

// remove role from user
export async function removeRoleFromUser(roleId: number, userId: number) {
    try {
        await http.DELETE<any>(`${ApiBaseUrl}/users/deleteuserrole?userId=${userId}&roleId=${roleId}`);
    } catch (error) {}
}

// delete from user
export async function deleteUser(userId: number) {
    try {
        await http.DELETE<any>(`${ApiBaseUrl}/users/${userId}`);
    } catch (error) {}
}

export const setSelectedVertical =
    (data: any): ThunkAction<void, AppState, undefined, any> =>
    async (dispatch, getState) => {
        dispatch({
            type: SET_VERTICAL_REQUEST
        });
        try {
            store.dispatch(SetVerticalSuccess(data));
        } catch (e) {
            dispatch({
                type: SET_VERTICAL_FAILURE,
                error: 'Failed to load permissions'
            });
        }
    };

export const setSelectedClient =
    (data: any): ThunkAction<void, AppState, undefined, any> =>
    async (dispatch, getState) => {
        dispatch({
            type: SET_CLIENT_REQUEST
        });
        try {
            store.dispatch(SetClientSuccess(data));
        } catch (e) {
            dispatch({
                type: SET_CLIENT_FAILURE,
                error: 'Failed to set client'
            });
        }
    };

export const LoadQuestions =
    (preAuditId: number, categoryId: number): ThunkAction<void, AppState, undefined, any> =>
    async (dispatch, getState) => {
        dispatch({
            type: GET_QUESTIONS_REQUEST
        });
        try {
            const response = await http.GET<any>(
                `${ApiBaseUrl}/preaudits/getanswers?preauditid=${preAuditId}&categoryid=${categoryId}`
            );

            store.dispatch(StartGetQuestionsSuccess(response.questions));
            return response.questions;
        } catch (e) {
            dispatch({
                type: GET_QUESTIONS_FAILURE,
                error: 'Failed to load permissions'
            });
        }
    };

export async function putUser(user: IUpdateUser) {
    try {
        await http.PUT<IUpdateUser>(`${ApiBaseUrl}/users`, {
            id: user.id,
            name: user.name,
            email: user.email,
            roles: user.roles
        });
    } catch (error) {}
}
